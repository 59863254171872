import React, { Component } from 'react'
import PageLoader from "./PageLoader";

class HomeFaq extends Component {
    
    state = {
        isLoading: true,
        active: -1
    };
    componentDidMount (){
        this.setState({
            isLoading: false,
        });

        //let vid = this.props.match.params.vid;
        //var userData = JSON.parse(logindata);
       
        // axios.get(window.apibaseurl + '/content/get_content/' + vid, { headers: { 'X-Client-ID': window.clientId, 'X-Localization' : window.localization } })
        // .then(resp=> {
        //     console.log(resp.data)
        //     this.setState({
        //         video: resp.data,
        //         isLoading: false,
        //         videoJsOptions: {
        //             autoplay: true,
        //             playbackRates: [0.5, 1, 1.25, 1.5, 2],
        //             controls: true,
        //             muted:false,
        //             showInfo: true,
        //             title: resp.data.title,
        //             backUrl: resp.data.parent_id === null ? '/detail/' + resp.data.id  : '/detail/' + resp.data.parent_id + '/',
        //             className: 'vjs-theme-city',
        //             thumb: resp.data.video[0].thumbnails["home_header"],
        //             sources: [{
        //               src: resp.data.video[0].url,
        //               type: 'application/x-mpegURL'
        //             }]
        //         }       
        //     })
        // })
    }
    toggleAccordion = (id, elm) => {
        
        this.setState({
            active : id === this.state.active ? -1 : id
        });
        
    }
    render() {
        return (
            this.state.isLoading 
            ? <PageLoader />
            : <section className="pt-150 container-box plr-200 plr-m-20">
                <h3 className="font-22 color-xlightgray text-center font-w500">Sıkça Sorulan Sorular</h3>
                <div className="pt-50 accordion">
                    <section id="acc-1" onClick={(elm) => this.toggleAccordion("1", elm) } key={"1"} className={this.state.active === "1" ? "accordion-item open": "accordion-item"}>
                        <h4 className="accordion-title">1.	Semerkand Seyir’i Nerelerde Seyredebilirim?</h4>
                        <div className="accordion-detail">Akıllı telefon, tablet ve bilgisayarınız vasıtasıyla Semerkand Seyir’i kullanabilirsiniz.</div>
                    </section>
                    <section id="acc-2" onClick={(elm) => this.toggleAccordion("2", elm) } key={"2"} className={this.state.active === "2" ? "accordion-item open": "accordion-item"}>
                        <h4 className="accordion-title">2.	Semerkand Seyir’i Aynı Hesapla Birden Fazla Cihazda Aynı Anda Seyredebilir Miyim?</h4>
                        <div className="accordion-detail">Semerkand Seyir, aynı anda iki farklı cihaz üzerinden seyredilebilmektedir. </div>
                    </section>
                    <section id="acc-3" onClick={(elm) => this.toggleAccordion("3", elm) } key={"3"} className={this.state.active === "3" ? "accordion-item open": "accordion-item"}>
                        <h4 className="accordion-title">3.	Semerkand Seyir’i Bilgisayarımı Televizyona Bağlayarak Seyredebilir Miyim?</h4>
                        <div className="accordion-detail">-	Semerkand Seyir’i HDMI veya VGA kablosu kullanarak yansıtma özelliği bulunan televizyonlara bilgisayar ile bağlayarak kullanabilirsiniz.</div>
                    </section>
                    <section id="acc-4" onClick={(elm) => this.toggleAccordion("4", elm) } key={"4"} className={this.state.active === "4" ? "accordion-item open": "accordion-item"}>
                        <h4 className="accordion-title">4.	Semerkand Seyir İçeriklerini Arkadaşlarımla Paylaşabilir Miyim?</h4>
                        <div className="accordion-detail">-	Paylaş butonunu kullanarak, Semerkand Seyir içeriklerinden dilediğiniz kişileri haberdar edebilirsiniz.</div>
                    </section>
                    <section id="acc-5" onClick={(elm) => this.toggleAccordion("5", elm) } key={"5"} className={this.state.active === "5" ? "accordion-item open": "accordion-item"}>
                        <h4 className="accordion-title">5.	Semerkand Seyir’in Kullanımı Kolay Mı? İçeriklere Nasıl Erişebilirim?</h4>
                        <div className="accordion-detail">-	Semerkand Seyir’in web sitesi ve uygulamalarında gördüğünüz herhangi bir içeriğe kolaylıkla erişebilirsiniz.</div>
                    </section>
                    <section id="acc-6" onClick={(elm) => this.toggleAccordion("6", elm) } key={"6"} className={this.state.active === "6" ? "accordion-item open": "accordion-item"}>
                        <h4 className="accordion-title">6.	Semerkand Seyir’i Kullanmak İnternet Kotamı Tüketir Mi?</h4>
                        <div className="accordion-detail">Semerkand Seyir’i kullandığınız tüm cihazlar internet kotanızı tüketir.</div>
                    </section>
                    {/* {
                        list.map(item=> {
                            return(
                                <section id={"acc-" + item} onClick={(elm) => this.toggleAccordion(item, elm) } key={item} className={this.state.active === item ? "accordion-item open": "accordion-item"}>
                                    <h4 className="accordion-title">Semerkand Digital’in Semerkand TV’den farklı nedir?</h4>
                                    <div className="accordion-detail">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, pariatur quas? Corrupti saepe facere ad aspernatur nemo quod aliquam beatae nostrum, aperiam dolorem qui iure fugiat doloremque reprehenderit repellendus quaerat!Praesentium nisi aliquam cumque aspernatur, labore quod totam? Quidem neque accusantium, nihil, nesciunt sapiente veniam fuga, aliquid explicabo officiis ipsa commodi velit veritatis quod amet? Ipsum dignissimos error dolorum fugit?</div>
                                </section>
                            )
                        })
                    } */}
                   
                </div>
            </section>
        )
    }
}

export default HomeFaq;