import React from 'react';
//import { findDOMNode } from 'react-dom'
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'
import api from '../services/api';
import screenfull from 'screenfull'
import Duration from './Duration'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import TokenService from "../services/token.service";

let intervalTime = 1;
let lastUpdateTime = 0;
let updateTime = 0;
let interval;
let updateInterval = 5;
let playedInterval;
let pausedInterval;
let volumeChangeInterval;
let forwarding = false;
let newVolume;
var controllerTimer;
var currentLevel = -1;
export default class VideoPlayer extends React.Component {
  state = {
    showControllers: true,
    isLoading: true,
    properties: {},
    url: '',
    pip: false,
    playing: true,
    controls: false,
    light: false,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playedSeconds: 0,
    playbackRate: 1.0,
    loop: false,
    seeking:false,
    volume: 1,
    volumeChanging:false,
    volumeCurrent:0.8,
    volumeLevel:3,
    qualityIndex:0,
    forwarding: false,
    showPaused: false,
    showPlayed: false,
    showVolumeChange:false,
    showMuted:false,
    showUnmuted:false,
    currentLevel: -1,
    levels:[]
  }
  constructor (props){
    super(props);
    this.setState({
        properties: this.props,
    });
  }
  ref = player => {
    this.player = player
  }
  componentDidMount() {
    //[VidQuality, setVidQuality] = this.useState(0);
    var playedSeconds = 0;
    if(this.props.watchHistory !== null && this.props.watchHistory.player_current_time !== undefined)
    {
      console.log("Geçmiş yüklendi")
      playedSeconds = this.props.watchHistory.player_current_time * 1;
    }
    
    var ssp = localStorage.getItem('sspdata');
    var sspData = JSON.parse(ssp);
    if(sspData != null){
      this.setState({
        volume: sspData.volume,
        volumeLevel: sspData.volumeLevel,
        volumeCurrent: sspData.volumeCurrent,
        muted: sspData.muted
      });
    }
    else{
      var playerData = {}
      playerData.volume = 1;
      playerData.volumeLevel = 3;
      playerData.volumeCurrent = 100;
      playerData.muted = false;
      localStorage.setItem('sspdata', JSON.stringify(playerData));
    }

    lastUpdateTime = playedSeconds;
    updateTime = playedSeconds;
    this.setState({
      url: this.props.sources[0].src,
      played: 50,
      playedSeconds: playedSeconds,
      loaded: 0,
      pip: false,
      isLoading: false
    });   
    document.addEventListener("keydown", this.escFunction, false);
    document.addEventListener('mousemove', (e) => {
      this.setState({
        showControllers: true
      })
      clearInterval(controllerTimer);
      controllerTimer = setTimeout(() => {
        this.setState({
          showControllers: false
        })
      }, 3000);    
    });
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }
  escFunction = (e) => {
    console.log(e.keyCode)
    if(e.keyCode === 32) {
      this.handlePlayPause();
    }
    if(e.keyCode === 38) {
      newVolume = this.state.volume + 0.01; 
      if(newVolume > 1) newVolume = 1;
      this.handleVolumeChange(newVolume)
      this.handleVolumeChangeComplete()
      
      this.setState({ showVolumeChange: true});
      clearTimeout(volumeChangeInterval)
      volumeChangeInterval = setTimeout(function(){
        this.setState({ showVolumeChange: false })
      }.bind(this), 500);
    }
    if(e.keyCode === 40) {
      newVolume = this.state.volume - 0.01; 
      if(newVolume < 0) newVolume = 0;
      this.handleVolumeChange(newVolume)
      this.handleVolumeChangeComplete()
      
      this.setState({ showVolumeChange: true});
      clearTimeout(volumeChangeInterval)
      volumeChangeInterval = setTimeout(function(){
        this.setState({ showVolumeChange: false })
      }.bind(this), 500);
    }
    if(e.keyCode === 77) {
      this.handleToggleMuted();
    }
    if(e.keyCode === 39) {
      this.handleFastForward();
    }
    if(e.keyCode === 37) {
      this.handleFastBackward();
    }
    if(e.keyCode === 70) {
      this.handleClickFullscreen();
    }
  }
  handlePlayPause = () => {
    var playing = !this.state.playing
    if(playing){
      this.setState({ playing: playing, showPlayed: true});
      clearTimeout(playedInterval)
      playedInterval = setTimeout(function(){
        this.setState({ showPlayed: false })
      }.bind(this), 1000);
    }
    else{
      this.setState({ playing: playing, showPaused: true});
      clearTimeout(pausedInterval)
      pausedInterval = setTimeout(function(){
        this.setState({ showPaused: false })
      }.bind(this), 1000);
    }
  }
  handleStop = () => {
  }
  handleToggleControls = () => {
    const url = this.state.url
    this.setState({
      controls: !this.state.controls,
      url: null
    }, () => this.load(url))
  }
  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }
  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }
  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }
  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }
  handlePlay = () => {
    this.setState({ playing: true })
  }
  handleReady = async () => {
    console.log("ready")
    console.log(this.player.getInternalPlayer('hls').autoLevelEnabled)
    this.setState({
      levels:this.player.getInternalPlayer('hls').levels
    })
    this.player.seekTo(parseFloat(this.state.playedSeconds))
  }
  handleChangeQuality = index => {
    console.log("level-" + index )
    currentLevel = index;
    if(index === -1){
      var currentPlayer = this.player.getInternalPlayer('hls');
      currentPlayer.currentLevel = index;
      currentPlayer.nextLevel = index;
    }
    else{
      var currentPlayer = this.player.getInternalPlayer('hls');
      currentPlayer.currentLevel = index;
      currentPlayer.nextLevel = index;
    }
    
    console.log(this.player.getInternalPlayer('hls').autoLevelEnabled)
  };
  handleEnablePIP = () => {
    this.setState({ pip: true })
  }
  handleDisablePIP = () => {
    this.setState({ pip: false })
  }
  handlePause = () => {
    this.setState({ playing: false })
  }
  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }
  handleSeekChange = e => {
    this.setState({ playedSeconds: parseFloat(e.target.value) })
  }
  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    interval = setTimeout(function(){
      this.player.seekTo(parseFloat(e.target.value))
    }.bind(this), 200);
  }
  handleFastForward = e => {
    clearTimeout(interval);
    if(forwarding){
      updateTime += 10;
    }
    else{
      forwarding = true;
      updateTime = this.state.playedSeconds;
    }
    if(updateTime > this.state.duration)
      updateTime = this.state.duration;
    this.player.seekTo(parseFloat(updateTime))
    this.setState({playedSeconds : updateTime, forwarding : false});
  }
  handleFastBackward = e => {
    clearTimeout(interval);
    if(forwarding){
      updateTime -= 10;
    }
    else{
      forwarding = true;
      updateTime = this.state.playedSeconds;
    }
    if(updateTime > this.state.duration)
      updateTime = this.state.duration;
    this.player.seekTo(parseFloat(updateTime))
    this.setState({playedSeconds : updateTime, forwarding : false});
  }
  handleProgress = state => {
    if (!this.state.seeking) {
      this.setState(state)
      this.setState({value: state.playedSeconds})
    }
    var playedSeconds = this.state.duration * this.state.played;
    this.setState({playedSeconds : playedSeconds});
    //console.log(playedSeconds);
    var validTime = lastUpdateTime*1 + updateInterval*1;

    if(this.props.updateWatchHistory){
      if(playedSeconds*1 >= validTime)
      {
        lastUpdateTime = playedSeconds; 
        this.updateWatchHistory(playedSeconds)
      }
    }
  }
  handleEnded = () => {
    this.setState({ playing: this.state.loop })
  }
  handleDuration = (duration) => {
    this.setState({ duration })
  }
  handleClickFullscreen = () => {
    screenfull.toggle();
  }
  updateWatchHistory(time){
    var loginUser = TokenService.getUser();
    if(loginUser === undefined  || loginUser === null || loginUser === "") return;
      
    var postData = {};
    postData.content_id = this.props.parentId;
    postData.finished = false;
    postData.player_current_time = time;
    postData.which_episode_user_left = this.props.videoId;
    api.post(window.apibaseurl + '/content/update_watch_history', postData)
    .then(resp=> {
        //console.log(resp.data)
    })
  }
  handleChangeStart = () => {
    this.setState({ seeking: true })
  };
  handleChange = value => {
    console.log(value)
    this.setState({
      playedSeconds: value
    })
    this.setState({ played: parseFloat(value) })
  };
  handleChangeComplete = () => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(this.state.played))    
  };  
  handleToggleMuted = () => {
    var muted = !this.state.muted;
    var volume = this.state.volumeCurrent;
    if(volume > 99) volume = 99;
    if(muted)
      volume = 0;
    clearTimeout(volumeChangeInterval)
    if(!muted){
      this.setState({ showMuted: true});
      volumeChangeInterval = setTimeout(function(){
        this.setState({ showMuted: false })
      }.bind(this), 500);
    }
    else{
      this.setState({ showUnmuted: true});
      volumeChangeInterval = setTimeout(function(){
        this.setState({ showUnmuted: false })
      }.bind(this), 500);
    }
    

    this.setState({ muted: muted, volume: volume });

    var playerData = {}
    playerData.volume = volume;
    playerData.volumeCurrent = this.state.volumeCurrent;
    playerData.volumeLevel = this.state.volumeLevel;
    playerData.muted = muted;
    localStorage.setItem("sspdata", JSON.stringify(playerData));
    console.log(volume)
  }
  handleVolumeChangeStart = () => {
    this.setState({ 
      volumeChanging: true, 
      volumeCurrent: this.state.volume 
    })
  };
  handleVolumeChange = value => {
    var level = Math.round(value * 100 / 33);
    if(level < 1) level = 1;
    this.setState({ 
      volume: Math.round(value * 100) / 100,
      volumeLevel: level
    })
    if(this.state.volume === 0)
      this.setState({ muted: true })
    else
      this.setState({ muted: false })
  };
  handleVolumeChangeComplete = () => {
    if(this.state.volume === 0)
      this.setState({ muted: true })
    else
      this.setState({ volumeCurrent: Math.round(this.state.volume * 100) / 100 })
    this.setState({ volumeChanging: false })

    var playerData = {}
    playerData.volume = this.state.volume;
    playerData.volumeLevel = this.state.volumeLevel;
    playerData.volumeCurrent = this.state.volume;
    playerData.muted = this.state.muted;
    localStorage.setItem("sspdata", JSON.stringify(playerData));
  };
  skipIntro = () => {
    //this.player.seekTo(parseFloat(updateTime))
    var updateTime = this.props.introEnd;
    this.setState({playedSeconds : updateTime, forwarding : false});
    this.player.seekTo(parseFloat(updateTime))
  }
  render () {
    const { url, playing, controls, light, volume, muted, loop, played, duration, playbackRate,playedSeconds, pip } = this.state;
    //const SEPARATOR = ' · '
    
    const formatPc = p => Math.round(p*100) + '%'
    return (
      this.state.isLoading 
      ? <div>Yükleniyor...</div>
      : <div className='ssp'>
        <section className='section'>
          {
            this.props.showInfo
            ?<div className={"video-info " + (this.state.showControllers ? "": "video-info-hide")}>
              <div className="display-t height-100">
                <div className="display-tc width-100 text-center valign-middle">
                  <Link to={this.props.backUrl} className="ssp-back-btn"><img src="/assets/images/ssp/back.png" alt="Geri" /></Link>
                </div>
              </div>
            </div>
            : null
          }
          <div className="ssp-info-icon">
            {
              this.state.showPlayed ? <span><i className="fa fa-play"></i></span> : null
            }
            {
              this.state.showPaused ? <span><i className="fa fa-pause"></i></span> : null
            }
            {
              this.state.showUnmuted ? <span className="image-icon"><img src="/assets/images/ssp/volume-mute@2x.png" alt="" /></span> : null
            }
            {
              this.state.showMuted ? <span className="image-icon"><img src={"/assets/images/ssp/volume-" + this.state.volumeLevel + "x@2x.png"} alt="" /></span> : null
            }
            {
              this.state.showVolumeChange ? <div className="volume-bar"><span><i style={{height:(volume *100) + '%'}}></i></span></div> : null
            }
          </div>
          {this.state.playedSeconds > this.props.introBegin && this.state.playedSeconds < this.props.introEnd 
          ? <Link to="#" onClick={this.skipIntro} className='intro-skip-btn'>Tanıtımı Atla <i className='fa fa-angle-double-right'></i></Link>
          : null
          }
          <div id="ssp-control" className={"plr-40 " + (this.state.showControllers ? "": "ssp-control-hide")}>
            <div className="display-t width-max">
              <div className="display-tc">
                <div className='video-timebar'>
                  <div className='video-timebar-content'>
                    <Slider
                      min={0}
                      max={duration}
                      value={playedSeconds}
                      onChangeStart={this.handleChangeStart}
                      onChange={this.handleChange}
                      onChangeComplete={this.handleChangeComplete}
                    />
                  </div>
                </div>
              </div>
              <div className="display-tc width-120 text-center font-16 font-w500">
                  <Duration seconds={duration * played} /> / <Duration seconds={duration} />
                  {/* <Duration seconds={duration * (1 - played)} /> */}
              </div>
            </div>
            <div className="display-t width-max">
              <div className="display-tc width-80 valign-middle">
                <button className="play-button" onClick={this.handlePlayPause}><img src={playing ? '/assets/images/ssp/pause.svg' : '/assets/images/ssp/play.svg'} alt="Oynat/Durdur" /></button>
              </div>
              <div className="display-tc width-150 valign-middle">
                <button className="fast-backward-button" onClick={this.handleFastBackward}><img src='/assets/images/ssp/fast-backward.svg' alt="10s geri sar" /></button>
                <button className="fast-forward-button" onClick={this.handleFastForward}><img src='/assets/images/ssp/fast-forward.svg' alt="10s ileri sar" /></button>
              </div>
              <div className="display-tc width-50 valign-middle ssp-volume-menu">
                <div className="ssp-volumebar">
                    <div className='ssp-volumebar-content'>
                      <Slider
                        min={0}
                        max={1}
                        step={0.01}
                        orientation={"vertical"}
                        value={volume}
                        format={formatPc}
                        onChangeStart={this.handleVolumeChangeStart}
                        onChange={this.handleVolumeChange}
                        onChangeComplete={this.handleVolumeChangeComplete}
                      />
                    </div>
                </div>
                <button className="volume-button" onClick={this.handleToggleMuted}>
                  {
                    <img src={muted ? '/assets/images/ssp/volume-mute.svg' : '/assets/images/ssp/volume-' + this.state.volumeLevel + 'x.svg'} alt="Ses Aç/Kapa" />
                  }
                </button>
              </div>
              <div className="display-tc text-center valign-middle font-24 font-w500">
                {this.props.title}
              </div>
              
              <div className='ssp-quality display-tc valign-middle'>
                <i className='fa fa-gear fa-3x'/>

                <div className="ssp-quality-menu">
                  <h3 className="font-18 font-w500 mb-20 text-center">Video Kalitesi</h3>
                  <div className="ssp-quality-menu-inputs">
                    <div><input id="quality--1" type="radio" name="abr-level" onClick={() => this.handleChangeQuality(-1)} onChange={() => this.handleChangeQuality(-1)} checked={this.state.currentLevel === -1} value={-1} /><label htmlFor="quality--1">Otomatik</label></div>
                    {
                    this.state.levels.map((level,index) => {
                        return <div key={"quality-" + index}><input id={"quality-" + index} type="radio" name="abr-level" onChange={() => this.handleChangeQuality(index)} checked={currentLevel === index} value={index} /><label htmlFor={"quality-" + index}>{level.height}</label></div>
                    })}
                  </div>
                </div>

              </div>
              <div className="ssp-speed display-tc width-70 valign-middle text-right pr-20">
                <img className="speed-btn" src='/assets/images/ssp/speedometer.svg' alt="Yürütme Hızı" />
                {
                  this.state.playbackRate === 1
                  ? <span></span>
                  : <span className="ssp-speed-btn-text">{this.state.playbackRate}x</span>
                }
                <div className="ssp-speed-menu">
                  <h3 className="font-24 font-w500 mb-40 text-center">Yürütme Hızı</h3>
                  <div className="ssp-speed-menu-inputs">
                    <input id="speed-050" type="radio" name="playbackRate" onChange={this.handleSetPlaybackRate.bind(this)} checked={this.state.playbackRate === 0.5} value={0.5} /><label htmlFor="speed-050">0.5x</label>
                    <input id="speed-075" type="radio" name="playbackRate" onChange={this.handleSetPlaybackRate.bind(this)} checked={this.state.playbackRate === 0.75} value={0.75} /><label htmlFor="speed-075">0.75x</label>
                    <input id="speed-100" type="radio" name="playbackRate" onChange={this.handleSetPlaybackRate.bind(this)} checked={this.state.playbackRate === 1} value={1} /><label htmlFor="speed-100">Normal</label>
                    <input id="speed-125" type="radio" name="playbackRate" onChange={this.handleSetPlaybackRate.bind(this)} checked={this.state.playbackRate === 1.25} value={1.25} /><label htmlFor="speed-125">1.25x</label>
                    <input id="speed-150" type="radio" name="playbackRate" onChange={this.handleSetPlaybackRate.bind(this)} checked={this.state.playbackRate === 1.5} value={1.5} /><label htmlFor="speed-150">1.5x</label>
                  </div>
                </div>
              </div>
              <div className="display-tc width-120 valign-middle">
                {screenfull.isFullscreen 
                  ? <button className="fullscreen-button" onClick={this.handleClickFullscreen}><img className="speed-btn" src='/assets/images/ssp/fullscreen-exit.svg' alt="Tam ekran" /></button>
                  : <button className="fullscreen-button" onClick={this.handleClickFullscreen}><img className="speed-btn" src='/assets/images/ssp/fullscreen.svg' alt="Tam ekran çık" /></button>
                }
              </div>
            </div>
          </div>
          <div id='ssp-fullpage-play' onClick={this.handlePlayPause}>

          </div>
         
          <div className='player-wrapper'>
            <ReactPlayer
              ref={this.ref}
              className='react-player ssp'
              width='100%'
              height='100vh'
              url={url}
              pip={pip}
              playing={playing}
              controls={controls}
              light={light}
              loop={loop}
              playbackRate={playbackRate}
              volume={volume}
              muted={muted}
              progressInterval={intervalTime * 1000}
              onReady={this.handleReady}
              //onStart={() => console.log('onStart')}
              onPlay={this.handlePlay}
              onEnablePIP={this.handleEnablePIP}
              onDisablePIP={this.handleDisablePIP}
              onPause={this.handlePause}
              //onBuffer={() => console.log('onBuffer')}
              //onSeek={e => console.log('onSeek', e)}
              onEnded={this.handleEnded}
              //onError={e => console.log('onError', e)}
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
            />
          </div>
        </section>
        
      </div>
    )
  }
}