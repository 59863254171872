import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.localization = 'tr';
//window.localization = 'ar';
window.clientId = 'eyJpdiI6IkE4WlpzZGR0Q1N5aUJsaDdZVzNVemc9PSIsInZhbHVlIjoiYk9sNkhKTFB1Ty80Z0pGZmNDbkNza3cwNnI5U1kyOVpDRSt3UVBDWXA5ND0iLCJtYWMiOiI1MjNmNDBhYjViZTg1YWZkYzczNjQ2NmNlMmVhZjhmZDNjMjM1M2U5NGFiNzQyMjcxZWMwMjcwZjFjOGQxODljIn0';
//window.clientId = 'eyJpdiI6IkZtUWM0YVNoWW1mc3dzQVdSL0dCaVE9PSIsInZhbHVlIjoialJNaG1BUjFZcThvVldqOXU0UmoxcTVoSnh6c2lzSGxBYUcxSkxpUnFnVT0iLCJtYWMiOiI2MGQ3ODc5YWU2N2U4OTViMjIwOWM2M2RmZDBiNjU2MjczOWFkMGUzOTBkNmY4MjNlNDkyNDBmMGUxYzNjYzRiIn0=';
window.parentId = 'eyJpdiI6InBaNk1KQjZPcUdVRTNuUmgzSHdUWXc9PSIsInZhbHVlIjoiNGNQS3A3WmgwaUJGQ0pMdmZkbExJUT09IiwibWFjIjoiMTc2NzFlN2M1MDNkYzRkMTQyZmYzN2YwYjdhOTJjMmVmNDFjNmFiZWM2YzRiNjA5YWRkZmE0YTc4OGU0NTkyMSJ9';
//window.parentId = 'eyJpdiI6ImQ2NjhvVzl5VzJLSFJpS0lwWDFkanc9PSIsInZhbHVlIjoib3dHTHhGUlp6YmpiUUhqQjRyN0M4QT09IiwibWFjIjoiYWIwOTFhNDQ2ZDkxODE3ODhlYmM3NzhkYjJiMzYyY2IzMWM0NWJlNjM3YWIwNzFkOWY1YTI1MzgwNDA5ZjgzYSJ9';
window.apibaseurl = "https://ottrestprod.mediatriple.net";
window.siteurl = "http://www.semerkandseyir.com";


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
