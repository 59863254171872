import React, { Component } from 'react'
import Header from './Header'
import Footer from "./Footer"
import PageLoader from "./PageLoader";
import DetailHighlight from "./DetailHighlight";
import api from '../services/api';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);

let sid;
let pid;
export class Detail extends Component {
    state = {
        isLoading: true,
        seasonId:-1,
        watchlistButtonSending: false,
        video: {...this.props}        
    }
    componentDidMount (){
        sid = this.state.video.current_season_id;
        pid = this.state.video.parent_id;
        api.get(window.apibaseurl + '/content/get_content/' + this.state.video.parent_id, {}, true)
        .then(resp=> {
            //console.log(resp.data)
            if(sid === undefined) sid = resp.data.seasons[0].id;
            this.setState({
                video: resp.data,
                isLoading: false,
                seasonId: sid *  1 
            })
        })
    }
    addList = (e) =>{
        console.log('ss')
        var logindata = localStorage.getItem('logindata');
        var userData = JSON.parse(logindata);
        
        if(userData == null){
            MySwal.fire({
                html: <p>Kullanıcı girişi yapmalısınız.</p>,
                icon: 'info',
                confirmButtonText: 'Kapat'
            
            })
            return;
        }
        this.watchlistButtonSendingToggle();
        api.post(window.apibaseurl + '/v2/content/add_to_watch_later/' + this.state.video.id, {}, true)
        .then(resp=> {
          
           console.log(resp.data)
           var video = {...this.state.video}
           video.watchlistadded = true;
           this.setState({
                video: video,
                watchlistButtonSending: false,
           });
        })
        .catch(function (error) {
            this.setState({
                watchlistButtonSending: false
           });
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
    removeList = (e) =>{
        console.log('ss')
        var logindata = localStorage.getItem('logindata');
        var userData = JSON.parse(logindata);

        if(userData == null){
            MySwal.fire({
                html: <p>Kullanıcı girişi yapmalısınız.</p>,
                icon: 'info',
                confirmButtonText: 'Kapat'
            
            })
            return;
        }
        this.watchlistButtonSendingToggle();
        api.post(window.apibaseurl + '/v2/content/remove_from_watch_later/' + this.state.video.id, {}, true)
        .then(resp=> {
           console.log(resp.data)
           var video = {...this.state.video}
           video.watchlistadded = false;
           this.setState({
                video: video,
                watchlistButtonSending: false
           });
        })
        .catch(function (error) {
            this.setState({
                watchlistButtonSending: false
            });
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
    watchlistButtonSendingToggle = (e) =>{
        var newStatus = !this.state.watchlistButtonSending;
        console.log(newStatus)
        this.setState({
            watchlistButtonSending: newStatus
        });
    }
    changeSeasson = (e)=>{
        console.log(this.state.video.parent_id)
        window.location.href = "/detail/" + pid + "/" + e.target.value;
    }
    render() {
        return (
            this.state.isLoading 
            ? <PageLoader />
            : <main className="clear-after">
                <Header/>
                <section className="home-showcase">
                {/* <img src={this.state.video.video[0].thumbnails[""]} alt="" srcSet="" /> */}
                {
                    this.state.video.video.length > 0 && this.state.video.video[0].thumbnails["home_header"] !== ""
                    ? <img src={this.state.video.video[0].thumbnails["home_header"]} alt="" srcSet="" />
                    : <img src="https://via.placeholder.com/1920x1080.png?text=." alt="" srcSet="" />
                }
                
                <div className="position-absolute left-0 right-0 top-0 bottom-0 zindex-50">
                    <div className="display-t height-100p width-max">
                        <div className="display-tc valign-middle height-100p">
                            <div className="container-box">
                                <h3 className="color-white font-26 font-w400">{this.state.video.content_types.map(item => {return( item + " ")})}</h3>
                                <h1 className="color-white font-80 font-bold text-shadow">{this.state.video.title}</h1>
                                
                                <div className="tags">
                                    <span>{this.state.video.content_types[0]}</span>
                                    {this.state.video.mood.map(function(el,index) {
                                        return <span href="/" key={index}>{el} </span>
                                    })}
                                </div>
                                <div className="season-list"> 
                                    <select onChange={this.changeSeasson} value={this.state.seasonId}>
                                        {this.state.video.seasons.map(function(el,index) {
                                            return <option value={el.id} key={index}>{el.title}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="clear-after">
                                    <p className="color-white font-16 font-w400 mt-20 col50 col75m col100s" dangerouslySetInnerHTML={{__html: this.state.video.description }}></p>
                                    <div className="clear col40">
                                        <div className="display-t font-12 color ptb-50 width-max">
                                            <div className="display-tc valign-middle pr-10">
                                                <Link className="color-white" to={'/watch/' +  this.state.video.id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>
                                                {/* {
                                                    this.state.video.content_type === "movie"
                                                    ? <Link className="color-white" to={'/watch/' +  this.state.video.id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                    : <Link className="color-white" to={'/watch/' +  this.state.video.seasons[0].episodes[0].id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                } */}
                                            </div>
                                            <div className="display-tc text-center">
                                                {
                                                    this.state.video.watchlistadded
                                                    ? <span className={"remove-watch-list" + (this.state.watchlistButtonSending ? ' sending': '')} onClick={this.removeList}>
                                                    <i className="fa fa-minus valign-middle mr-5"></i> Listeden Çıkar</span>   
                                                    : <span className={"add-watch-list" + (this.state.watchlistButtonSending ? ' sending': '')} onClick={this.addList}>
                                                    <i className="fa fa-plus valign-middle mr-5"></i> Listeye Ekle</span>   
                                                    
                                                }
                                            </div>
                                            <div className="display-tc pl-10 text-right">
                                                <span><i className="fa fa-share-alt fa-3x valign-middle mr-5"></i> Paylaş</span>    
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                
                <section className="">
                <div className="container-box clear-after">
                    <h4 className="font-24 font-w500">Bölümler</h4>
                    <div className="seperator seperator-gray mtb-30"></div>
                    <div className="episodes">
                        {
                            this.state.video.seasons.filter(item=> item.id === this.state.video.current_season_id)
                                .map(item =>  {
                                var episodes = item.episodes;
                                return(
                                episodes.map(episode => {
                                    return(
                                        <div className="mb-10" key={episode.id}>
                                            <div className="display-t width-max">
                                                <div className="relative display-tc valign-top width-250">
                                                    <Link to={"/watch/"+ episode.id} className="display-b color-white cover">
                                                        <div className="display-t width-max height-100p">
                                                            <div className="display-tc valign-middle text-center">
                                                                <i className="fa fa-play-circle fa-3x"></i>
                                                            </div>
                                                            
                                                        </div>
                                                    </Link>
                                                    {
                                                        this.state.video.video.length > 0 && this.state.video.video[0].thumbnails["home_cover"] !== "" 
                                                        ? <img src={this.state.video.video[0].thumbnails["horizontal"]} alt="" className="responsive" />
                                                        : <img src={"https://via.placeholder.com/400x200.png?text=." + episode.title} alt="" srcSet="" />
                                                    }
                                                    
                                                </div>
                                                <div className="display-tc valign-middle pl-20">
                                                    <Link to={"/detail/" + episode.id} className="color-white mb-10">
                                                    <span className="font-16 color-white display-ib" dangerouslySetInnerHTML={{__html: episode.title }}></span><span className=" ml-20">{episode.episode_order}. Bölüm</span>
                                                    </Link>
                                                    <div className="font-12 color-gray mb-10" dangerouslySetInnerHTML={{__html: item.title }}></div>
                                                    <p className="font-16 color-white mb-10" dangerouslySetInnerHTML={{__html: episode.description }}></p>
                                                    <div className="font-12 color-gray display-ib">{episode.duration}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                )
                                
                            })
                        }
                        
                    </div>
                </div>
                </section>
                <section className="">
                <div className="container-box clear-after">
                    <div className="episodes">
                        {
                            this.state.video.seasons.filter(item=> item.id === this.state.seasonId)
                                .map(item =>  {
                                var episodes = item.episodes;
                                return(
                                episodes.map(episode => {
                                    return(
                                        <div className="mb-40" key={episode.id}>
                                            <div className="display-t width-max">
                                                <div className="relative display-tc valign-top width-250">
                                                    <Link to={"/watch/"+ episode.id} className="display-b color-white cover">
                                                        <div className="display-t width-max height-100p">
                                                            <div className="display-tc valign-middle text-center">
                                                                <i className="fa fa-play-circle fa-3x"></i>
                                                            </div>
                                                            
                                                        </div>
                                                    </Link>
                                                    {
                                                        this.state.video.video.length > 0 && this.state.video.video[0].thumbnails["home_header"] !== "" 
                                                        ? <img src={this.state.video.video[0].thumbnails["horizontal"]} alt="" className="responsive" />
                                                        : <img src={"https://via.placeholder.com/400x200.png?text=." + episode.title} alt="" srcSet="" />
                                                    }
                                                </div>
                                                <div className="display-tc valign-middle pl-20">
                                                    <Link to={"/detail/" + episode.id} className="mb-10">
                                                    <span className="font-16 color-white display-ib" dangerouslySetInnerHTML={{__html: episode.title }}></span><span className=" ml-20">{episode.episode_order}. Bölüm</span>
                                                    </Link>
                                                    <div className="font-12 color-gray mb-10" dangerouslySetInnerHTML={{__html: item.title }}></div>
                                                    <p className="font-16 color-white mb-10" dangerouslySetInnerHTML={{__html: episode.description }}></p>
                                                    <div className="font-12 color-gray display-ib">{episode.duration}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                )
                                
                            })
                        }
                        
                    </div>
                </div>
                </section>

                <DetailHighlight/>
                <Footer isLogin={false}/>
            </main>
            
        )
    }
}
export default Detail