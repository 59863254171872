import React from 'react'
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'

//const {a,b,c} = this.props;
function Footer(props) {
        return (
            <footer className="pt-20 pb-50 mt-150 mt-m-50">
                
                <div className="container">
                    
                    <div className="row hidden show-m mb-20">
                        <div className="footer-menu">
                            <a href="/">Ana Sayfa</a>
                            {/* <a href="/">Kurumsal Bilgiler</a> */}
                            <Link to="/terms-of-use">Kullanım Koşulları</Link>
                            {/* <a href="/">Geri Bildirim</a> */}
                            <Link to="/yardim">Yardım</Link>
                            <Link to="/sss">S.S.S.</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col20 col50m text-left">
                            <img src="/Assets/images/logo.png" height="30" className="display-ib valign-middle" alt=""/>
                            <div className="store-icons">
                                <a href="https://apps.apple.com/tr/app/semerkand-seyir/id1589931890?l=tr" rel="noreferrer" target="_blank"><i className="fa fa-apple"></i></a>
                                <a href="https://play.google.com/store/apps/details?id=com.semerkand.seyir&hl=tr&gl=US" rel="noreferrer" target="_blank"><i className="fa fa-play"></i></a>
                            </div>
                        </div>
                        <div className="col60 hidden-m">
                            <div className="footer-menu">
                                <a href="/">Ana Sayfa</a>
                                {/* <a href="/">Kurumsal Bilgiler</a> */}
                                <Link to="/kurumsal-bilgiler">Kurumsal Bilgiler</Link>
                                <Link to="/terms-of-use">Kullanım Koşulları</Link>
                                {/* <a href="/">Geri Bildirim</a> */}
                                <Link to="/yardim">Yardım</Link>
                                <Link to="/sss">S.S.S.</Link>
                            </div>
                        </div>
                        <div className="col20 col50m text-right socialmedia-links">
                            <a href="https://www.facebook.com/semerkandseyir" target="_blank" rel="noreferrer"><i className="fa fa-facebook-square"></i></a>
                            <a href="https://www.twitter.com/semerkandseyir" target="_blank" rel="noreferrer"><i className="fa fa-twitter-square"></i></a>
                            <a href="https://www.instagram.com/semerkandseyir" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                            <a href="https://www.youtube.com/channel/UC4UoGTQnyyCEete9rT3UMzQ" target="_blank" rel="noreferrer"><i className="fa fa-youtube-play"></i></a>
                        </div>
                    </div>
                    <div className="color-gray font-12 mt-30">
                        © 1997-2022 Semerkand
                    </div>
                </div>
            </footer>
        )
}
Footer.propTypes = {
    isLogin: PropTypes.bool.isRequired
}
Footer.default = {
    isLogin: "false"
}
export default Footer;