import React, { Component } from 'react'
import Header from "./Header"
import HomeContentGroups from './HomeContentGroups';
import Footer from "./Footer"

class Dashboard extends Component {
    render() {
        return (
            <main className="clear-after">
                
                <Header/>
                
              
                {/* 
                <HomeShowcase/>

                <HomeSubscribe/>

                <Highlight/>
              
                <HomeEpisodes/>
                
                <HomeVideosForChild />
                    
                <HomeDiscover/> */}

                <HomeContentGroups />

                <section className="mt-150 mt-s-50 container-box text-center plr-200 plr-s-0">
                <h3 className="font-26 font-w500 color-xlightgray">Semerkand Seyir’i tabletinizde, telefonunuzda ve bilgisayarınızda keyifle kullanabilirsiniz.</h3>
                <div className="plr-150 plr-s-0 pt-50">
                    <img src="/Assets/images/VodDevices2.png" className="responsive-image" alt=""/>
                </div>
                </section>
                <Footer isLogin={false}/>

                <div id="video-previewmodal"></div>
            </main>
        )
    }
}
export default Dashboard;