import React, { Component } from 'react'
import Header from './Header'
import Footer from "./Footer"
import DetailHighlight from "./DetailHighlight";
import ShareModal from "./ShareModal";
import WatchListButton from "./WatchListButton";
import { Link } from 'react-router-dom';

export class Detail extends Component {
    state = {
        isOpen: false,
        seasonId:-1,
        watchlistButtonSending: false,
        video: {...this.props}        
    }
    componentDidMount (){
        console.log(this.state.video)
    }
    
    changeSeasson = (e)=>{
        window.location.href = "/detail/" + this.state.video.current_id + "/" + e.target.value;
    }
    render() {
        return (
            <main className="series-detail clear-after">
                <Header/>
                <section className="series-showcase">
                <div className="container-box">
                {
                    this.state.video.video.length > 0 && this.state.video.video[0].thumbnails["home_cover"] !== "" 
                    ? <img src={this.state.video.video[0].thumbnails["home_cover"]} alt="" srcSet="" />
                    : <img src="https://via.placeholder.com/1920x1080.png?text=." alt="" srcSet="" />
                }
                </div>
                
                <div className="series-showcase-content">
                    <div className="display-t height-100p width-max">
                        <div className="display-tc valign-middle height-100p">
                            <div className="container-box series-showcase-detail">
                                {
                                    this.state.video.is_original === 1 
                                    ? <h3 className="color-white font-26 font-m-20 font-s-16 font-w200"><b className="font-w500">SEYİR</b> ORIGINAL</h3>
                                    : ""
                                }                                
                                <h1 className="color-white font-80 font-m-60 font-s-30 font-bold">{this.state.video.title}</h1>
                                
                                <div className="tags">
                                    <span>{this.state.video.content_types[0]}</span>
                                    {this.state.video.mood.map(function(el,index) {
                                        return <span to={"/search-result/" + el} key={index}>{el} </span>
                                    })}
                                </div>
                                <div className="clear-after">
                                    <p className="detail-description mt-20 col30 col75m col100s" dangerouslySetInnerHTML={{__html: this.state.video.description }}></p>
                                    <div className="clear col30 col100m">
                                        <div className="display-t font-12 color ptb-50 width-max">
                                            <div className="display-tc valign-middle pr-10">
                                            <Link className="color-white" to={'/watch/' +  this.state.video.id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>
                                                {/* {
                                                    this.state.video.content_type === "movie"
                                                    ? <Link className="color-white" to={'/watch/' +  this.state.video.id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                    : <Link className="color-white" to={'/watch/' +  this.state.video.seasons[0].episodes[0].id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                } */}
                                            </div>
                                            
                                                {
                                                    <div className="display-tc text-center">
                                                        <WatchListButton 
                                                        imagePath = {this.state.video.video[0].thumbnails["horizontal"]} 
                                                        episodeId =  {this.state.video.id} 
                                                        id = {this.state.video.id} 
                                                        title = {this.state.video.title}
                                                    /> 
                                                    </div>
                                                }
                                                
                                            <div className="display-tc pl-10 text-right">
                                                <ShareModal shareUrl={ window.siteurl + "/detail/" + this.state.video.id }>
                                                    <i className="fa fa-share-alt fa-3x valign-middle mr-5 cursor-pointer"></i> Paylaş
                                                </ShareModal>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                <DetailHighlight key={1}/>
                <Footer isLogin={false}/>
            </main>
            
        )
    }
}
export default Detail
