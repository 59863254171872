import React, { Component } from 'react'
import Header from './Header'
import Footer from "./Footer"
import DetailHighlight from "./DetailHighlight";
import ShareModal from "./ShareModal";
import WatchListButton from "./WatchListButton";
import { Link } from 'react-router-dom';

export class Detail extends Component {
    state = {
        isOpen: false,
        seasonId:-1,
        watchlistButtonSending: false,
        video: {...this.props}        
    }
    componentDidMount (){
        console.log(this.state.video)
    }
    
    changeSeasson = (e)=>{
        window.location.href = "/detail/" + this.state.video.current_id + "/" + e.target.value;
    }
    render() {
        return (
            <main className="series-detail clear-after">
                <Header/>
                <section className="series-showcase">
                <div className="container-box">
                {
                    this.state.video.video.length > 0 && this.state.video.video[0].thumbnails["home_cover"] !== "" 
                    ? <img src={this.state.video.video[0].thumbnails["home_cover"]} alt="" srcSet="" />
                    : <img src="https://via.placeholder.com/1920x1080.png?text=." alt="" srcSet="" />
                }
                </div>
                
                <div className="series-showcase-content">
                    <div className="display-t height-100p width-max">
                        <div className="display-tc valign-middle height-100p">
                            <div className="container-box series-showcase-detail">
                                {
                                    this.state.video.is_original === 1 
                                    ? <h3 className="color-white font-26 font-m-20 font-s-16 font-w200"><b className="font-w500">SEYİR</b> ORIGINAL</h3>
                                    : ""
                                }
                                
                                <h1 className="color-white font-80 font-m-60 font-s-30 font-bold">{this.state.video.title}</h1>
                                
                                <div className="tags">
                                    <span>{this.state.video.content_types[0]}</span>
                                    {this.state.video.mood.map(function(el,index) {
                                        return <span key={index}>{el} </span>
                                    })}
                                </div>
                                <div className="season-list"> 
                                    <select onChange={this.changeSeasson} value={this.state.video.current_season_id}>
                                        {this.state.video.seasons.map(function(el,index) {
                                            return <option value={el.id} key={index}>{el.title}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="clear-after">
                                    <p className="detail-description mt-20 col30 col75m col100s" dangerouslySetInnerHTML={{__html: this.state.video.description }}></p>
                                    <div className="clear col30 col100m">
                                        <div className="display-t font-12 color ptb-50 width-max">
                                            <div className="display-tc valign-middle pr-10">
                                            <Link className="color-white" to={'/watch/' +  this.state.video.seasons[0].episodes[0].id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>
                                                {/* {
                                                    this.state.video.content_type === "movie"
                                                    ? <Link className="color-white" to={'/watch/' +  this.state.video.id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                    : <Link className="color-white" to={'/watch/' +  this.state.video.seasons[0].episodes[0].id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                } */}
                                            </div>
                                            
                                                {
                                                    this.state.video.video.length > 0
                                                    && this.state.video.seasons.length > 0
                                                    && this.state.video.seasons[0].episodes.length > 0
                                                    ? <div className="display-tc text-center">
                                                        <WatchListButton 
                                                        imagePath = {this.state.video.video[0].thumbnails["horizontal"]} 
                                                        episodeId = {this.state.video.seasons[0].episodes[0].id} 
                                                        id = {this.state.video.id} 
                                                        title = {this.state.video.title}
                                                    /> 
                                                    </div>
                                                    : null
                                                }
                                                
                                            <div className="display-tc pl-10 text-right">
                                                <ShareModal shareUrl={ window.siteurl + "/detail/" + this.state.video.id }>
                                                    <i className="fa fa-share-alt fa-3x valign-middle mr-5 cursor-pointer"></i> Paylaş
                                                </ShareModal>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                <section className="">
                
                <div className="container-box clear-after">
                    <h4 className="font-24 font-w500">Bölümler</h4>
                    <div className="seperator seperator-gray mtb-30"></div>
                    <div className="episodes">
                        {
                            this.state.video.seasons.filter(item=> item.id === this.state.video.current_season_id)
                                .map(item =>  {
                                var episodes = item.episodes;
                                return(
                                episodes.map(episode => {
                                    var imagePath = this.state.video.video.length > 0 && this.state.video.video[0].thumbnails["horizontal"] !== "" 
                                    ? this.state.video.video[0].thumbnails["horizontal"]
                                    : "https://via.placeholder.com/400x200.png?text=." + episode.title
                                    return(
                                            <div className="episode-item" key={episode.id}>
                                                <div className="episode-thumb" style={{backgroundImage: `url(${imagePath})`}}>
                                                    <Link to={"/watch/"+ episode.id} className="display-b color-white cover">
                                                        <div className="display-t width-max height-100p">
                                                            <div className="display-tc valign-middle text-center">
                                                                <i className="fa fa-play"></i>
                                                            </div>
                                                            
                                                        </div>
                                                    </Link>
                                                    <img src={imagePath} alt="" className="responsive" />
                                                </div>
                                                <div className="episode-info">
                                                    <Link to={"/watch/" + episode.id} className="font-16 color-white mb-10">
                                                    <span className="">{episode.episode_order}. Bölüm </span> 
                                                    {
                                                        episode.title === ""
                                                        ? ""
                                                        :<span className="color-white display-ib"> - { episode.title }</span>
                                                    }
                                                    
                                                    </Link>
                                                    <div className="font-12 color-gray mb-10" dangerouslySetInnerHTML={{__html: item.title }}></div>
                                                    <p className="font-16 color-white mb-10 hidden-s" dangerouslySetInnerHTML={{__html: episode.description }}></p>
                                                    <div className="font-12 color-gray display-ib">{episode.duration}</div>
                                                    <div className='mt-10'>
                                                        <ShareModal shareUrl={ window.siteurl + "/watch/"+ episode.id} shareText={episode.episode_order + ". Bölüm - " + episode.title} >
                                                            <span className='btn btn-black'>
                                                                <i className="fa fa-share-alt font-18 valign-middle cursor-pointer"></i> Bölümü Paylaş
                                                            </span> 
                                                        </ShareModal>
                                                    </div>
                                                </div>
                                                <p className="font-16 color-white mb-10 episode-description" dangerouslySetInnerHTML={{__html: episode.description }}></p>
                                                
                                            </div>
                                    )
                                })
                                )
                                
                            })
                        }
                        
                    </div>
                </div>
                </section>
                <DetailHighlight key={1}/>
                <Footer isLogin={false}/>
            </main>
            
        )
    }
}
export default Detail
