import React, { Component } from 'react'
import SquareImageViewer from "./SquareImageViewer";
import Loader from "./Loader";
import api from '../services/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link} from 'react-router-dom'
import SwiperCore, { Navigation} from 'swiper';
import 'swiper/swiper.min.css';
    
SwiperCore.use([Navigation]);
    export default class HomeContentGroup extends Component {
        state = {
            isPageLoader: true,
            content:[]            
        }
        async componentDidMount() {
            try {
                var homediscover = localStorage.getItem('group_' + this.props.id);
                var jsonData = JSON.parse(homediscover);
                
                if(homediscover === undefined || homediscover === null || homediscover === "" || jsonData.expireat < new Date().getTime()){
                    api.get(window.apibaseurl + '/content/get_content_group/' + this.props.id, {}, true)
                    .then(resp=> {
                        var data = {}
                        var date = new Date();
                        data.expireat = date.getTime() + 15*60000
                        data.content = resp.data;
                        //console.log(data)
                        this.setState({
                            isPageLoader:false,
                            content: data.content
                        })
                        localStorage.setItem('group_' + this.props.id, JSON.stringify(data))
                    })
                }
                else{
                    this.setState({
                        isPageLoader:false,
                        content: jsonData.content
                    })
                }
            } catch(err) {
                console.log(err)
            }
        }
        // componentDidMount(){            
            
            
        // }
        render() {
            return (
                this.state.content.content !== undefined && this.state.content.content.length > 0
                ?
                    this.state.isPageLoader
                    ? <section className="container-box ptb-150 relative">
                        <Loader />
                    </section>
                    : <section className="container-box overflow-hidden ptb-40">
                        <h3 className="font-22 color-white hover-color-theme2 font-w500 mb-20 display-ib">{this.state.content.content_group_name}</h3>
                        <Link to={"/category/" + this.state.content.content_group_id} className="content-group-detail-btn"><i className="fa fa-arrow-right"></i></Link>
                        <div className="seperator seperator-theme3 mb-30"></div>
                        <MySwiper content={this.state.content} />
                    </section>
                :
                <div></div>
            )
            
        }
    }

    export function MySwiper(props) {
        const navigationPrevRef = React.useRef(null)
        const navigationNextRef = React.useRef(null)
        return (
            <Swiper
                grabCursor={TextTrackCue}
                spaceBetween={10}
                slidesPerView={2}
                slidesPerGroup={2}
                loop={true}
                allowTouchMove={false}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        slidesPerGroup:3,
                        spaceBetween: 10
                    },
                    991: {
                        slidesPerView: 3,
                        slidesPerGroup:3,
                        spaceBetween: 20
                    },
                    1200: {
                        slidesPerView: 4,
                        slidesPerGroup:4
                    },
                    1500: {
                        slidesPerView: 4,
                        slidesPerGroup:4
                    }
                }}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onSwiper={(swiper) => {
                    // Delay execution for the refs to be defined
                    setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
            
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                    })
                }}
            >
            {
                props.content.content.map(function(content,index) {
                    var parent_id = content.parent_id;
                    if(parent_id === undefined || parent_id === null){ 
                        parent_id = content.id;
                    }
                    const rand = 10000 + Math.random() * (99999 - 10000);
                    if(content.video.length > 0){
                        return <SwiperSlide key={index}>
                            <div className="">
                            <SquareImageViewer                                             
                                id={content.id}
                                rid = {rand}
                                parentId = {parent_id}
                                title={content.title ?? ""}
                                thumb={content.video[0].thumbnails["horizontal"]}
                                videoUrl={content.video[0].url}
                                preview={false}
                            />
                        </div>
                        </SwiperSlide>
                    }
                    return <SwiperSlide key={index}>
                        <div className="">
                            <SquareImageViewer 
                                id={content.id}
                                rid = {rand}
                                parentId = {parent_id}
                                title={ "[" + content.title + "]"}
                                thumb={"https://via.placeholder.com/733x446.png?text=" + content.title}
                                videoUrl={""}
                                preview={false}
                            />
                        </div>
                        </SwiperSlide>
                })
            }
            <div ref={navigationPrevRef} className="homevideos-left" ><p><i className="fa fa-angle-left"></i></p></div>
            <div ref={navigationNextRef} className="homevideos-right" ><p><i className="fa fa-angle-right"></i></p></div>
            </Swiper>
        )
    }