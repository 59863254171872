import axios from "axios";
import TokenService from "./token.service";


var localization = 'tr';
var clientId = 'eyJpdiI6IkE4WlpzZGR0Q1N5aUJsaDdZVzNVemc9PSIsInZhbHVlIjoiYk9sNkhKTFB1Ty80Z0pGZmNDbkNza3cwNnI5U1kyOVpDRSt3UVBDWXA5ND0iLCJtYWMiOiI1MjNmNDBhYjViZTg1YWZkYzczNjQ2NmNlMmVhZjhmZDNjMjM1M2U5NGFiNzQyMjcxZWMwMjcwZjFjOGQxODljIn0';
//var parentId = 'eyJpdiI6InBaNk1KQjZPcUdVRTNuUmgzSHdUWXc9PSIsInZhbHVlIjoiNGNQS3A3WmgwaUJGQ0pMdmZkbExJUT09IiwibWFjIjoiMTc2NzFlN2M1MDNkYzRkMTQyZmYzN2YwYjdhOTJjMmVmNDFjNmFiZWM2YzRiNjA5YWRkZmE0YTc4OGU0NTkyMSJ9';

const instance = axios.create({
  baseURL: "http://ottrestprod.mediatriple.net",
  headers: {
    "Content-Type": "application/json",
    "X-Client-ID": clientId,
    "X-Localization" : localization
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["Authorization"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post("/auth/refresh", {});
          const { token, token_type } = rs.data;
          TokenService.updateLocalAccessToken(token_type, token);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;