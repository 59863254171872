import  {BrowserRouter, Route, Switch} from 'react-router-dom'
import React, { Component } from 'react';
import Dashboard from "./components/Dashboard"
import Detail from "./components/Detail"
import Watch from "./components/Watch"
import Login from "./components/Login"
import Register from "./components/Register"
import ForgotPassword from "./components/ForgotPassword"
import ResetPassword from "./components/ResetPassword"
import TermsOfUse from "./components/TermsOfUse"
import Account from "./components/Account"
import Faq from "./components/Faq"
import Help from "./components/Help"
import ChangePassword from "./components/ChangePassword"
import ChangeMobile from "./components/ChangeMobile"
import ChangeMail from "./components/ChangeMail"
import ChangeInfo from "./components/ChangeInfo"
import MyList from "./components/MyList"
import SearchResult from "./components/SearchResult"
import { Category } from './components/Category'
import Premium from './components/Premium';
import PrivacyPolicy from './components/PrivacyPolicy';
import Corporate from './components/Corporate';
import { BrowserView, MobileView } from 'react-device-detect';
import TokenService from './services/token.service';
import MobileScreen from './components/MobileScreen';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

class App extends Component {
  state = {
    authProps: {
      premium: false,
      expireAt: 0
    },
  }
  componentDidMount (){
    var logindata = TokenService.getUser();
    if(logindata !== undefined  && logindata !== null && logindata !== "") {
      this.setState({
        authProps: {
          premium: true,
          expireAt: 0
        }
      })
    }

    //var querystring = require('querystring');
    //let params = querystring.stringify({username: '+905074299135'});
    //axios.post('https://panel.edergilik.com/api/auth/subscriberbyusername',  params, { headers: { "Content-Type": "application/x-www-form-urlencoded" }})
    //.then(resp=> {
    //    this.setState({
    //      authProps: {
    //        premium: true,
    //        expireAt: resp.data.subscriptionEndDate
    //      }
    //    });
    //})
    //.catch(function (error) {
    //  console.log(error);
    //  if(error.response.status === 400){
    //      console.log("premium hesap değil")
    //  }
    //  else{
    //      alert('Üyelik doğrulanamadı!')
    //  }
    //});

}
  render(){
    return (
      
      <BrowserRouter>
        <div className="App">
          
        <BrowserView>
          <div id="main">
            <Route path='/' exact  >
            { 
              this.state.authProps.premium === true
              ? <Dashboard auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route component={Login} path='/login' />
            <Route component={Register} path='/register' />
            <Route component={TermsOfUse} path='/terms-of-use' />
            <Route component={Faq} path='/sss' />
            <Route component={Help} path='/yardim' />
            <Route component={Corporate} path='/kurumsal-bilgiler' />
            <Route component={PrivacyPolicy} path='/privacy-policy' />
            <Route component={ForgotPassword} path='/forgot-password' />
            <Route component={ResetPassword} path='/reset-password/:token' />
            
            <Route path='/detail/:vid/' render={() => 
              this.state.authProps.premium === true
              ? <Detail auth={this.authProps}/>
              : <Premium auth={this.authProps}/>}  >
            </Route>
            <Route path='/detail/:vid/:sid/' render={() => 
              this.state.authProps.premium === true
              ? <Detail auth={this.authProps}/>
              : <Premium auth={this.authProps}/>}  >
            </Route>
       
            <Route path='/watch/:vid/'  >
            { 
              this.state.authProps.premium === true
              ? <Watch auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route  path='/account'  >
            { 
              this.state.authProps.premium === true
              ? <Account auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route path='/my-list' >
            { 
              this.state.authProps.premium === true
              ? <MyList auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route path='/search-result/:word'  >
            { 
              this.state.authProps.premium === true
              ? <SearchResult auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route path='/change-password'  >
            { 
              this.state.authProps.premium === true
              ? <ChangePassword auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route path='/change-mobile'  >
            { 
              this.state.authProps.premium === true
              ? <ChangeMobile auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route path='/change-mail'  >
            { 
              this.state.authProps.premium === true
              ? <ChangeMail auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route path='/change-info'  >
            { 
              this.state.authProps.premium === true
              ? <ChangeInfo auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>
            <Route path='/category/:cid'  >
            { 
              this.state.authProps.premium === true
              ? <Category auth={this.authProps}/>
              : <Premium auth={this.authProps}/>
            }
            </Route>

          </div>
      </BrowserView>
      <MobileView>
        
        <Switch>
          <Route component={ResetPassword} path='/reset-password/:token' />
          <Route component={MobileScreen} />
        </Switch>
      </MobileView>
      </div>
      
    </BrowserRouter>
  );
  }
}

export default App;

