import React, { Component } from 'react'

export default class PageLoader extends Component {
    render() {
        return (
            <div className="pageload">
                <div className="spinner">
                    <div className='lds-ring'><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        )
    }
}
