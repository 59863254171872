import React, { Component } from 'react'
import Header from "./Header"
import Footer from "./Footer"

class Help extends Component {
    render() {
        return (
            <main className="clear-after">
                <Header/>
                
                <section className="pt-150 container-box plr-200 plr-m-20">
                    <h3 className="font-22 color-xlightgray text-center font-w500">SEMERKAND SEYİR YARDIM</h3>
                    <div className="pt-50 help-content">
                        <h3>1.	NEDEN SEMERKAND SEYİR?</h3>

                        <h4>a)	SEMERKAND SEYİR NEDİR?</h4>
                        <p>
                        •	Semerkand Seyir, içeriklerini “ailecek seyredilebilir” özellikte sunan ve içeriklerine internet üzerinden erişilebilen bir dijital video platformudur.
                        </p>

                        <h4>b)	İÇERİKLER HAZIRLANIRKEN VEYA SEÇİLİRKEN İSLAMİ HASSASİYETLER DİKKATE ALINIYOR MU?</h4>

                        <p>
                        •	Semerkand TV ve Semerkand Seyir orijinal içerikleri İslâmi hassasiyetler doğrultusunda hazırlanıp sunulmaktadır. Dış yapımlar ise alanında uzman kişilerin incelemelerinden geçtikten sonra Semerkand Seyir’de yayınlanmaktadır.
                        </p>
                        <h4>
                        c)	ÇOCUKLAR SEMERKAND SEYİR’İ GÜVENLE KULLANABİLİR Mİ?
                        </h4>
                        <p>
                        •	Tüm içeriklerin ailecek seyretmeye uygun olmasının yanında çocuklar için hazırlanmış dini-eğitim, çizgi film, yarışma programı gibi birçok içerik Semerkand Seyir’de bulunmaktadır.
                        </p>
                        <h3>
                        2.	ÜYELİK
                        </h3>
                        <h4>
                        a)	NASIL ÜYE OLABİLİRİM?
                        </h4>
                        <p>
                        Uygulamalarda ve internet sitesinde yer alan üye ol alanındaki adımları takip ederek üyelik sürecini nihayete erdirebilirsiniz.
                        </p>
                        <h4>
                        b)	ÜYELİK ÜCRETİ NE KADAR?
                        </h4>
                        <p>
                        Uygulama ve internet sitesi anasayfasında güncel üyelik ücretleri yer almaktadır. 
                        </p>
                        <h4>
                        c)	ÜYELİĞİMİ NASIL İPTAL EDEBİLİRİM?
                        </h4>
                        <p>
                        Hesabım alanında yer alan üyelik işlemleri kısmında iptal sürecini nihayete erdirebilirsiniz.
                        </p>
                        <h4>
                        d)	ÜYELİĞİMİ İPTAL ETTİĞİM AN İÇERİKLERE ERİŞİMİM SONLANIR MI?
                        </h4>
                        <p>
                        Üyelik paketinizde belirtilen süre dolana dek içerik erişiminiz devam eder. Paket süreniz dolduğunda erişim hakkınız da sona ermiş olur.
                        </p>
                        <h4>e)	ÜYELİK BİLGİLERİMİ NASIL DEĞİŞTİREBİLİRİM?</h4>
                        <p>
                        Hesabım alanından gerekli güncellemeleri yapabilirsiniz.
                        </p>

                        <h4>f)	SEMERKAND SEYİR UYGULAMASINI NEREDEN İNDİREBİLİRİM?</h4>
                        <p>
                            •	Android telefonlar için Google Play Store, Apple cihazlar için Apple Store üzerinden Semerkand Seyir uygulamasını indirebilirsiniz.
                        </p>

                        <h3>3.	İÇERİK</h3>

                        <h4>a)	SEMERKAND SEYİR’DE NE TÜR İÇERİKLER VAR?</h4>

                        <p>
                        •	Semerkand Seyir’de belgesel, dini-eğitim, dini-kültürel, çocuk, çocuk-eğitim ve kadın kategorilerinde içerikler mevcut.
                        </p>



                        <h4>b)	İÇERİKLER AİLECEK SEYREDİLEBİLİR NİTELİKTE Mİ?</h4>
                        <p>
                        -Semerkand Seyir’de yer alan içeriklerin tamamı ailecek gönül rahatlığıyla seyredebileceğiniz niteliktedir. 
                        </p>


                        <h3>4.	YURT DIŞINDA KULLANIM</h3>

                        <h4>a)	Semerkand Seyir’i yurtdışında seyredebilir miyim? </h4>
                        <p>
                        -Evet fakat Semerkand Seyir, telif haklarını, dublaj ve altyazı özelliklerini dikkate alarak farklı coğrafyalar için farklı içerikler sunabilir.
                        </p> 

                        <h4>b)	Türkiye dışından tüm içeriklere ulaşabilir miyim?</h4>
                        <p>-	Semerkand TV ve Semerkand Seyir Orijinal içeriklerini hem yurt içinde hem yurt dışında seyredebilirsiniz. Bunlar dışındaki içeriklerin yayını telif hakları sebebiyle değişkenlik gösterebilir.</p>

                        <h4>c)	Semerkand Seyir’de yabancı dillerde içerikler mevcut mu?</h4>
                        <p>-	Arapça, Kürtçe, Boşnakça, Rusça ve Almanca içerikler mevcuttur.</p>


                        <h3>5.	İÇERİKLERİ SEYREDERKEN YAŞANAN PROBLEMLER</h3>
                        <p>
                        Semerkand Seyir içeriklerini seyrederken donma, takılma gibi problemler yaşarsanız aşağıdaki önerileri uygulayabilirsiniz.
                        <br/><br/>
                        •	İnternete bağlı olduğunuzdan ve bağlantınızın düzgün çalıştığından emin olunuz.<br/>
                        •	İnternete bağlı olduğunuz halde kesinti yaşıyorsanız modeminizi yeniden başlatınız yahut mobil verinizi kapatıp açınız.<br/>
                        •	Aynı interneti birden fazla cihaz kullanıyorsa diğer cihazların bağlantısını kesiniz.<br/>
                        •	Cihazın veya bilgisayarın güncellemelerini kontrol ediniz. Güncel değilse güncelleyiniz.<br/>
                        </p>

                        <h3>6.	SATIN ALMA</h3>
                        <h4>a)	BANKA/KREDİ KARTIM İNTERNET ALIŞVERİŞLERİNE KAPALI OLDUĞU İÇİN ÖDEME YAPAMIYORUM ÇÖZÜMÜ NEDİR?</h4>
                        <p>Müşterisi olduğunuz banka ile iletişime geçerek kartınızı internet alışverişlerine açtırabilirsiniz.      </p>

                        <h4>b)	BANKA KARTI İLE ÖDEME YAPABİLİR MİYİM?</h4>
                        <p>İnternet alışverişine açık olan banka kartlarıyla üyelik işlemlerinizi gerçekleştirebilirsiniz.</p>

                    
                    </div>
                </section>
                <section className="mt-150"></section>
                                     
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default Help;