import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import qs from 'qs';
import AuthService from "../services/auth.service";
import PremiumService from "../services/premium.service";
import TokenService from "../services/token.service";

let emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
const MySwal = withReactContent(Swal);
class Login extends Component {
  state = {
    loginByPhone: true,
  };
  //constructor(props){
  //    super(props);
  //}
  // componentDidMount() {
  //     //API isteklerini buradan yap
  // }
  // componentDidUpdate(prevProps, prevState) {
  //     // Güncelleme sonrası çalışır
  // }
  // shouldComponentUpdate(nextProps, nextState) {
  //     // Değişim yansımasın istiyorsan return:false döndürebiliriz.
  // }
  // componentWillUnmount() {
  //     // Component kaldırıldığında çalışır
  // }
  componentDidMount() {
    this.loadCountryCodes();
  }
  chageLoginType() {
    var newState = !this.state.loginByPhone;
    this.setState({
      loginByPhone: newState,
    });

    if (newState) {
      this.loadCountryCodes();
    }
  }
  loadCountryCodes() {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    axios
      .get("/countrylist.json", {}, headers)
      .then((resp) => {
        var list = resp.data;
        var sel = document.getElementById("countryCode");
        for (var i = 0; i < list.length; i++) {
          var opt = document.createElement("option");
          opt.innerHTML = list[i]["countryCode"];
          opt.value = list[i]["countryCode"];
          sel.appendChild(opt);
        }
        //localStorage.setItem('my_watch_later_list', JSON.stringify(list));
      })
      .catch(function (error) {
        console.log("ülke listesi yüklenemedi!");
      });
  }
  render() {
    return (
      <main className="clear-after overflow-hidden">
        <section className="register-page ptb-200">
          <LoginForm
            loginByPhone={this.state.loginByPhone}
            chageLoginType={this.chageLoginType.bind(this)}
          />
        </section>
      </main>
    );
  }
}
export default Login;

export function LoginForm(props) {
  const [sendingForm, sendingToggle] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  let byPhone = props.loginByPhone;
  const chageLoginType = () => {
    byPhone = !byPhone;
    props.chageLoginType();
  };

  const onSubmit = (data) => {
    data.client_id = window.clientId;
    data.parent_id = window.parentId;

    delete data["email"];
    delete data["phone"];
    if (byPhone) {
      data.username =
        document.getElementById("countryCode").value +
        document.getElementById("phone").value.replace(/^0+/, "");
      data.login_type = "phone";
    } else {
      data.username = document.getElementById("email").value;
      data.login_type = "email";
    }
    sendingToggle(true);

    AuthService.login(
      data.username,
      data.password,
      byPhone ? "phone" : "email"
    ).then(
      (response) => {
        if (response !== null && response !== undefined) {
          PremiumService.control(response.data.user.phone)
            .then((resp) => {
              console.log(resp);
              sendingToggle(false);
              if (resp == null) {
                AuthService.premiumControl(data.username).then((result) => {
                  if (result === false) {
                    MySwal.fire({
                      html: <p>Hesabınız premium değil</p>,
                      icon: "info",
                      confirmButtonText: "Kapat",
                    });
                  } else {
                    TokenService.setUser(response.data);
                    MySwal.fire({
                      html: (
                        <p>
                          Giriş işlemi başarılı, yönlendirilirken lütfen
                          bekleyin.
                        </p>
                      ),
                      icon: "success",
                      showConfirmButton: false,
                    });
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 2000);
                  }
                });
              } else {
                var ToDate = new Date();
                if (
                  new Date(resp.data.subscriptionEndDate).getTime() >=
                  ToDate.getTime()
                ) {
                  TokenService.setUser(response.data);
                  MySwal.fire({
                    html: (
                      <p>
                        Giriş işlemi başarılı, yönlendirilirken lütfen bekleyin.
                      </p>
                    ),
                    icon: "success",
                    showConfirmButton: false,
                  });
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 2000);
                } else {
                  MySwal.fire({
                    html: <p>Premium süreniz sona ermiş!</p>,
                    icon: "info",
                    confirmButtonText: "Kapat",
                  });
                }
              }
            })
            .catch((error) => {
              //console.log(error)

              AuthService.premiumControl(response.data.user.phone).then(
                (result) => {
                  if (result === false) {
                    sendingToggle(false);
                    MySwal.fire({
                      html: <p>Hesabınız premium değil</p>,
                      icon: "info",
                      confirmButtonText: "Kapat",
                    });
                  } else {
                    TokenService.setUser(response.data);
                    MySwal.fire({
                      html: (
                        <p>
                          Giriş işlemi başarılı, yönlendirilirken lütfen
                          bekleyin.
                        </p>
                      ),
                      icon: "success",
                      showConfirmButton: false,
                    });
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 2000);
                  }
                }
              );

              //MySwal.fire({
              //    html: <p>Premium hesaba sabip değilsiniz!</p>,
              //    icon: 'info',
              //    confirmButtonText: 'Kapat'
              //});
            });
        } else sendingToggle(false);
      },
      (error) => {
        //const resMessage = ( error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        sendingToggle(false);
        if (error.response) {
          if (error.response.status === 400) {
            MySwal.fire({
              html: <p>{error.response.data.message}</p>,
              icon: "info",
              confirmButtonText: "Kapat",
            });
          }
        }
      }
    );

    return;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="register-form ptb-50 plr-30"
    >
      <h1 className="font-30 font-w600 color-white text-center">Giriş Yap</h1>
      <div className="text-right pt-20 pb-10">
        {byPhone ? (
          <button
            type="button"
            onClick={chageLoginType}
            className="login-by-btn"
          >
            <i className="fa fa-envelope valign-middle mlr-5"></i> E-posta ile
            giriş yap
          </button>
        ) : (
          <button
            type="button"
            onClick={chageLoginType}
            className="login-by-btn"
          >
            <i className="fa fa-phone valign-middle mlr-5"></i> Telefon ile
            giriş yap
          </button>
        )}
      </div>
      {byPhone ? (
        <div className="display-t width-max">
          <div className="display-tc width-80">
            <select
              id="countryCode"
              className="form-select form-select-dark mtb-5"
            ></select>
          </div>
          <div className="display-tc pl-10">
            <input
              id="phone"
              name="phone"
              type="text"
              ref={register({ required: true })}
              className={
                "form-input form-input-dark " + (errors.phone ? " error" : "")
              }
              placeholder="Telefon numaranız"
            />
          </div>
        </div>
      ) : (
        <div>
          <input
            id="email"
            name="email"
            type="text"
            ref={register({ required: true, pattern: emailPattern })}
            className={
              "form-input form-input-dark mtb-5" +
              (errors.email ? " error" : "")
            }
            placeholder="Mail adresiniz"
          />
        </div>
      )}

      <div>
        <input
          id="password"
          name="password"
          type="password"
          className={
            "form-input form-input-dark mtb-5" +
            (errors.password ? " error" : "")
          }
          placeholder="Şifreniz"
          ref={register({
            required: true,
          })}
        />
      </div>

      <button
        type="submit"
        className={
          "btn btn-white btn-rounded width-max mt-20 font-w500" +
          (sendingForm === true ? " sending" : "")
        }
      >
        Giriş Yap
      </button>

      <div className="text-center mt-10">
        <Link to="/forgot-password" className="color-white font-14">
          <i className="fa fa-unlock-alt valign-middle mr-5"></i> Şifremi
          Unuttum
        </Link>
      </div>
      <p className="font-16 color-gray font-w400 text-center mt-30">
        Bir hesabınız yok mu?{" "}
        <Link to="/register" className="color-white">
          Şimdi Kaydolun
        </Link>
      </p>
    </form>
  );
}
