import React, { Component } from 'react'
import {Link} from 'react-router-dom'

export default class MobileScreen extends Component {
  componentDidMount() {

    setTimeout(() => {
      window.location='android-app://com.semerkand.seyir/https/www.semerkandseyir.com'; //doesn't work
    }, 500);
  }
  render() {
    return (
        <div className='display-t width-max height-100vh'>
            <div className='text-center display-tc valign-middle'>
              <img src="/Assets/images/logo.png" alt="Semerkan Seyir"/>
              <h1 className='font-24 font-w400 mt-20'>Uygulamayı Hemen İndir!</h1>
              <h2 className='font-18 font-w400 color-theme2 mb-20'>Çok daha rahat bir şekilde seyret</h2>
              <Link to='https://apps.apple.com/tr/app/semerkand-seyir/id1589931890?l=tr' className='display-ib mr-5' target={'_blank'} ref={'noopener noreferrer'}>
                <img srcSet='/Assets/images/appstore-logo.png' alt='Semerkand Seyir Apple Appstore'/>
              </Link>
              <Link to='https://play.google.com/store/apps/details?id=com.semerkand.seyir&hl=tr&gl=US' className='display-ib ml-5' target={'_blank'} ref={'noopener noreferrer'}>
                <img srcSet='/Assets/images/google-logo.png' alt='Semerkand Seyir Google Play Store' />
              </Link>
            </div>
          </div>
    )
  }
}