import React, { Component } from "react";
import PageLoader from "./PageLoader";
import DetailForSeries from "./DetailForSeries";
import DetailForEpisode from "./DetailForEpisode";
import DetailForMovie from "./DetailForMovie";
import axios from "axios";
import api from "../services/api";

let vid;
let sid = -1;
export class Detail extends Component {
  state = {
    isLoading: true,
    isAddedWatchList: false,
    seasonId: -1,
    video: {},
  };
  componentDidMount() {
    vid = window.location.pathname.split("/")[2];
    sid = window.location.pathname.split("/")[3];
    var localization = "tr";
    var clientId =
      "eyJpdiI6IkE4WlpzZGR0Q1N5aUJsaDdZVzNVemc9PSIsInZhbHVlIjoiYk9sNkhKTFB1Ty80Z0pGZmNDbkNza3cwNnI5U1kyOVpDRSt3UVBDWXA5ND0iLCJtYWMiOiI1MjNmNDBhYjViZTg1YWZkYzczNjQ2NmNlMmVhZjhmZDNjMjM1M2U5NGFiNzQyMjcxZWMwMjcwZjFjOGQxODljIn0";

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "X-Client-ID": clientId,
      "X-Localization": localization,
    };

    axios
      .get(
        window.apibaseurl + "/content/get_content/" + vid,
        { headers: headers },
        headers
      )
      .then((resp) => {
        console.log(resp);
        if (
          resp.data.content_type === "series" &&
          sid === undefined &&
          resp.data.seasons.length > 0
        )
          sid = resp.data.seasons[0].id;
        this.setState({
          video: resp.data,
          isLoading: false,
          seasonId: sid * 1,
        });
      });
  }
  renderSwitch(param) {
    const options = this.state.video;
    options.current_season_id = sid * 1;
    options.current_id = vid * 1;
    options.watchlistadded = this.state.isAddedWatchList;
    switch (param) {
      case "series":
        return <DetailForSeries {...options} />;
      case "episode":
        return <DetailForEpisode {...options} />;
      case "movie":
        return <DetailForMovie {...options} />;
      default:
        return "";
    }
  }
  render() {
    return this.state.isLoading ? (
      <PageLoader />
    ) : (
      this.renderSwitch(this.state.video.content_type)
    );
  }
}
export default Detail;
