import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from "./Footer"
import { useForm } from "react-hook-form";
import api from '../services/api'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

class ChangePassword extends Component {
    state = {
        isPageLoader: true,
        videos:[]            
    }
    componentDidMount(){
        var logindata = localStorage.getItem('logindata');
        var userData = JSON.parse(logindata);
        console.log(userData)
    }
    render() {
        return (
            <main className="clear-after overflow-hidden">
                <Header/>
                <section className="ptb-200 bg-xxlightgray color-themedark">
                    <div className="container bg-white p-50 border border-color-xlightgray border-width-2">
                        <h2 className="font-24 mb-20">Şifre Güncelle</h2>
                        <p className="font-16 lineheight-18">
                        
                        </p>
                        <ChangePasswordForm />
                    </div>
                </section>
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default ChangePassword;


export function ChangePasswordForm() {
    const [sendingForm, sendingToggle] = useState(false);
    const {register, handleSubmit, getValues, errors } = useForm();
    
    const onSubmit = data => {
        sendingToggle(true);
        api.post(window.apibaseurl + '/user/change_password', data)
        .then(resp=> {
           console.log(resp)
           
            MySwal.fire({
                html: <p>{resp.data.message}</p>,
                icon: 'success',
                confirmButtonText: 'Kapat'
            })
            sendingToggle(false);
        })
        .catch(function (error) {
            sendingToggle(false);
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
                else {
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
     
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="changepassword-form ptb-50" autoComplete="off">
            <div className="width-250">
                <div className="mtb-10">
                    <label className="form-label font-14">Mevcut Şifre</label>
                    <input type="text" id="current_password" name="current_password" className={"form-input form-input-large form-input-rounded" + (errors.current_password ? " error" : "")} 
                        ref={register({ 
                            required: true
                        })}
                    />
                </div>
                <div className="mtb-10">
                    <label className="form-label font-14">Yeni Şifre</label>
                    <input id="newpassword" name="new_password" type="text" className={"form-input form-input-large form-input-rounded" + (errors.new_password ? " error" : "")} 
                        ref={register({ 
                            required: true
                        })}
                    />
                </div>
                <div className="mtb-10">
                    <label className="form-label font-14">Yeni Şifre (Tekrar)</label>
                    <input id="repassword" name="new_password_confirmation" type="text" className={"form-input form-input-large form-input-rounded" + (errors.new_password_confirmation ? " error" : "")} 
                        ref={register({ 
                            required: true,
                            validate: {
                                matchesPreviousPassword: value => {
                                const { new_password } = getValues();
                                if(value === "" || value === null || value === undefined) return "Yeni şifreyi tekrar giriniz";
                                return new_password === value || "notmatch";
                                }
                            }
                        })} 
                    />
                        
                </div>
                {errors.new_password_confirmation && (
                      errors.new_password_confirmation.message === "notmatch" 
                      ?<div className="box bg-lightred">
                            Şifreler eşleşmiyor!
                        </div>
                    : null
                )}
                <div className="mtb-30">
                    <Link to="/account" className="font-w600 btn btn-white-o btn-rounded"><i className="fa fa-chevron-left"></i> Geri</Link>
                    <button type="submit" className={"font-w600 btn btn-black btn-rounded ml-30" + (sendingForm === true ? " sending": "")}>Güncelle</button>
                </div>
            </div>
        </form>
    );
  }