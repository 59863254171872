import React, { Component } from 'react'
import PageLoader from "./PageLoader";
import VideoPlayer from "./VideoPlayer";
import api from '../services/api';
import TokenService from '../services/token.service';

export class Watch extends Component {
    state = {
        isLoading: true,
        video: {},
        videoJsOptions: {}
    }
    componentDidMount (){
        let vid = window.location.pathname.split('/')[2];
       
        api.get(window.apibaseurl + '/content/get_content/' + vid, {},true)
        .then(resp=> {
            //console.log(resp.data)
            var introBegin = resp.data.intro_beginning_duration;
            var introEnd = resp.data.intro_end_duration;

            if(introBegin == null) introBegin = "0:0:0";
            if(introEnd == null) introEnd = "0:0:0";

            var a = introBegin.split(':');
            var b = introEnd.split(':');
            var introBeginSecond = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
            var introEndSecond = (+b[0]) * 60 * 60 + (+b[1]) * 60 + (+b[2]); 
            console.log(introBeginSecond)
            console.log(introEndSecond)
            var videoJsOptions = {
                videoId: resp.data.id,    
                parentId: resp.data.parent_id !== null ? resp.data.parent_id : resp.data.id,
                autoplay: true,
                updateWatchHistory: true,
                playbackRates: [0.5, 1, 1.25, 1.5, 2],
                controls: true,
                muted:false,
                loop:false,
                showInfo: true,
                title: resp.data.title,
                introBegin: introBeginSecond,
                introEnd: introEndSecond,
                watchHistory: null,
                backUrl: resp.data.parent_id === null ? '/detail/' + resp.data.id  : '/detail/' + resp.data.parent_id,
                className: '',
                thumb: resp.data.video[0].thumbnails["home_header"],
                sources: [{
                  src: resp.data.video[0].url,
                  type: 'application/x-mpegURL'
                }]
            }   
            
            var loginUser = TokenService.getUser();
            if(loginUser !== undefined  && loginUser !== null && loginUser !== ""){
                api.get(window.apibaseurl + '/content/get_player_current_time/' + resp.data.id, {}, true)
                .then(resp2=> {                    
                    videoJsOptions.watchHistory = resp2.data;
                    this.setState({
                        video: resp.data,
                        isLoading: false,
                        videoJsOptions: videoJsOptions  
                    })
                })
                .catch(function (error) {
                    console.log("watch history list loaded error")
                });               
            }
            else{
                this.setState({
                    video: resp.data,
                    isLoading: false,
                    videoJsOptions: videoJsOptions  
                })
            }
        })
    }
    render() {
        return (
            this.state.isLoading 
            ? <PageLoader />
            : <main className="clear-after watch-page">
                <VideoPlayer { ...this.state.videoJsOptions } />
            </main>
            
        )
    }
}
export default Watch;