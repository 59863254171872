import React, { Component } from 'react'
import SquareImageViewer from "./SquareImageViewer";
import Loader from "./Loader";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation} from 'swiper';

import 'swiper/swiper.min.css';
import api from '../services/api';
    
SwiperCore.use([Navigation]);

export default class HomeOngoing extends Component {
    state = {
        isPageLoader: true,
        content:[]            
    }
    componentDidMount(){            
        var homeongoing = localStorage.getItem('homeongoing');
        var jsonData = JSON.parse(homeongoing);

        if(homeongoing === undefined || homeongoing === null || homeongoing === "" || jsonData.expireat < new Date().getTime()){
            api.get('/content/get_my_ongoing_contents', {}, true)
            .then(resp=> {
                var data = {}
                var date = new Date();
                data.expireat = date.getTime() + 15*60000
                data.content = resp.data;
                localStorage.setItem('homeongoing', JSON.stringify(data))
                this.setState({
                    content: data.content,
                    isPageLoader:false
                })
            })
        }
        else{
            this.setState({
                isPageLoader:false,
                content: jsonData.content
            })
        }
        
    }

    render() {
        return (
            this.state.content !== undefined && this.state.content.length > 0
            ?
                this.state.isPageLoader
                ? <section className="container-box overflow-hidden ptb-150 relative">
                    <Loader />
                  </section>
                : <section className="pt-40">
                    <div className='container-box'>
                    <h3 className="font-22 color-white hover-color-theme2 font-w500 mb-20 display-ib">Kaldığın Yerden Devam Et</h3>
                    </div>
                    <div className="seperator seperator-theme3 mb-30"></div>
                    <MySwiper contents={this.state.content} />
                </section>
            :
            <div></div>
        )
        
    }
}

export function MySwiper(props) {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    return (
        props.contents == null && props.contents.length <= 0
        ? <div>İçerik</div>
        : <Swiper
            spaceBetween={0}
            slidesPerView={2}
            slidesPerGroup={2}
            grabCursor={TextTrackCue}
            loop={true}
            allowTouchMove={false}
            breakpoints={{
                768: {
                    slidesPerView: 3,
                    slidesPerGroup:3
                },
                991: {
                    slidesPerView: 3,
                    slidesPerGroup:3
                },
                1200: {
                    slidesPerView: 4,
                    slidesPerGroup:4
                },
                1500: {
                    slidesPerView: 5,
                    slidesPerGroup:5
                },
            }}
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper) => {
                // Delay execution for the refs to be defined
                setTimeout(() => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    swiper.params.navigation.nextEl = navigationNextRef.current
        
                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                })
            }}
        >
        {
            props.contents.map(function(content,index) {
                var parent_id = content.parent_id;
                if(parent_id === undefined || parent_id === null){ 
                    parent_id = content.id;
                }
                const rand = 10000 + Math.random() * (99999 - 10000);
                if(content.video.length > 0){
                    return <SwiperSlide key={index}>
                    <div>
                        <SquareImageViewer                                             
                            key={content.pivot.which_episode_user_left}
                            id={content.pivot.which_episode_user_left}
                            rid = {rand}
                            parentId = {parent_id}
                            title={content.title ?? ""}
                            thumb={content.video[0].thumbnails["horizontal"]}
                            videoUrl={content.video[0].url}
                            detailUrl= {'/detail/' + content.id}
                            preview={false}
                        />
                    </div>
                    </SwiperSlide>
                }
                return <SwiperSlide key={index}>
                    <div>
                        <SquareImageViewer 
                            id={content.id}
                            rid = {rand}
                            parentId = {parent_id}
                            title={ "[" + content.title + "]"}
                            thumb={"https://via.placeholder.com/733x446.png?text=" + content.title}
                            videoUrl={""}
                            detailUrl= {'/detail/' + content.id}
                            preview={false}
                        />
                    </div>
                    </SwiperSlide>
            })
        }
        <div ref={navigationPrevRef} className="homeepisodes-left" ><p><i className="fa fa-angle-left"></i></p></div>
        <div ref={navigationNextRef} className="homeepisodes-right" ><p><i className="fa fa-angle-right"></i></p></div>
        </Swiper>
    )
}