import React, { Component } from 'react'
import Footer from './Footer';
import Header from './Header';
class PrivacyPolicy extends Component {

    render() {
        return (
            <main className="clear-after overflow-hidden pt-150 mt-s-100">
                <Header />
                <div className="container html-content font-16 lineheight-15">

                    <h1 className="font-18 font-w600 mb-20">SEMERKAND SEYİR GİZLİLİK İLKESİ</h1>

                    <p>1. Giriş</p>
                    <p>2. Semerkand Seyir Hizmeti'ni Kullanımınız</p>
                    <p>3. İçerik ve Fikri Mülkiyet Hakları</p>


                    <h2 className="mt-20 mb-10">1. Giriş</h2>

                    <p>
                        Bu Gizlilik İlkeler'i içeren veya bu İlkeler ile bağlantılı tüm web sitelerimiz ve yazılım uygulamalarımız (toplu olarak "Semerkand Seyir Hizmeti") ve Semerkand Seyir Hizmeti aracılığıyla kullanıma sunulan her türlü müzik, video veya diğer materyal ("İçerik") dahil olmak üzere video ve başka içeriklerin akışı için SemerkandSeyir'in kişiselleştirilmiş hizmetlerini kullanımınız (bunlara erişim dahil) bu Gizlilik İlkeleri'ne tabi olacağından lütfen bu Gizlilik İlkeleri'ni ("Gizlilik İlkeleri") dikkatli bir şekilde okuyun.
                    </p>
                    <p>
                        Semerkand Seyir Hizmeti'ninin kullanımı, Semerkand Seyir tarafından sunulacak ve bu Gizlilik İlkeler'e referans yoluyla ilave edilecek ek gizlilik ilkelerine veya koşullarına tabi olabilir.
                    </p>
                    <p>
                        Semerkand Seyir Hizmeti'ne kaydolarak veya başka bir şekilde kullanarak bu Gizlilik İlkeler'i kabul edersiniz. Bu Gizlilik İlkeleri'ni kabul etmiyorsanız Semerkand Seyir Hizmeti'ni kullanmamalı veya herhangi bir İçerik'e erişmemelisiniz.
                    </p>

                    <h3 className="mt-20 mb-5">Hizmet Sağlayıcı</h3>

                    <p>Bu Gizlilik İlkeleri siz ve Semerkand Seyir (Eyüp Sultan Mah. Esma Sk. No:7/A 34885 SamandıraSancaktepe - İSTANBUL / TÜRKİYE arasındadır.
                    </p>

                    <h2 className="mt-20 mb-10">2. Semerkand Seyir Hizmeti'ni Kullanımınız</h2>

                    <h3 className="mt-20 mb-5">Semerkand Seyir hesabı oluşturma</h3>
                    <p>
                        Semerkand Seyir Hizmeti'nin tamamını veya bir kısmını kullanabilmek için bir Semerkand Seyir hesabı oluşturmanız gerekebilir. Kullanıcı adınız ve parolanız kişisel kullanımınıza özeldir ve gizli tutulmalıdır. Kullanıcı adınızın ve parolanızın her türlü kullanımından (her türlü yetkisiz kullanım dahil) sizin sorumlu olduğunuzu anlarsınız. Kullanıcı adınız veya parolanız kaybolur ya da çalınırsa veya hesabınıza yetkisiz bir giriş olduğunu düşünüyorsanız hemen Müşteri Hizmetleri ekibimizi bilgilendirin.
                    </p>
                    <p>
                        Semerkand Seyir herhangi bir gerekçeyle kullanıcı adınızı geri alabilir veya değiştirmenizi gerekli kılabilir.
                    </p>
                    
                    <h2 className="mt-20 mb-10">3. İçerik ve Fikri Mülkiyet Hakları</h2>

                    <h3 className="mt-20 mb-5">Geri Bildirim</h3>
                    <p>
                        Semerkand Seyir Hizmeti veya herhangi bir İçerik ile bağlantılı olarak geri bildirim, fikir veya öneri ("Geri Bildirim") sunmanız halinde ilgili Geri Bildirim gizli olmayacaktır ve Semerkand Seyir bu Geri Bildirim'i sınırlama olmaksızın ve size ödeme yapmaksızın kullanabilir. Bu Şartlar kapsamında Geri Bildirim, bir tür Kullanıcı İçeriği olarak düşünülür.
                    </p>
                    
                    <h3 className="mt-20 mb-5">Cihazınız.</h3>
                    <p>
                        Bize ayrıca şu hakları verirsiniz: (1) Semerkand Seyir Hizmeti'nin işleyişini kolaylaştırmak amacıyla Semerkand Seyir Hizmeti'nin Cihazınızdaki işlemciyi, bant genişliğini ve depolama donanımını kullanmasına izin vermek, (2) size reklam ve diğer bilgileri sunmamıza ve Semerkand Seyir Gizlilik Politikası uyarınca izin verildiği üzere iş ortaklarımızın da aynısını yapmasına izin vermek.
                    </p>

                    <h3 className="mt-20 mb-5">İçerik Deneyimi</h3>
                    <p>
                        Semerkand Seyir Hizmeti'nin herhangi bir kısmında, seçimi ve yerleşimi dahil olmak üzere eriştiğiniz İçerik, SemerkandSeyir'ın üçüncü taraflarla sözleşmeleri dahil olmak üzere ticari hususlardan etkilenebilir.
                    </p>
                    <p>
                        Semerkand Seyir tarafından lisanslanan, sağlanan, oluşturulan veya başka bir şekilde kullanıma sunulan bazı İçerikler (ör. diziler) reklam içerebilir ve ilgili reklamlardan Semerkand Seyir sorumlu değildir.
                    </p>

                    <h3 className="mt-20 mb-5">İhlal iddiaları</h3>
                    <p>
                        Semerkand Seyir, fikri mülkiyet hakkı sahiplerinin haklarına saygı duyar. Herhangi bir İçerik'in telif haklarınızı ihlal ettiğini düşünüyorsanız lütfen Semerkand Seyir Telif Hakkı Politikası'nı inceleyin.
                    </p>


                </div>
                <Footer />
            </main >
        )
    }
}
export default PrivacyPolicy;

