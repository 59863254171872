import React, { Component } from 'react'
import Header from "./Header"
import Footer from "./Footer"

class Corporate extends Component {
    render() {
        return (
            <main className="clear-after">
                <Header/>
                
                <section className="pt-150 container-box plr-200 plr-m-20">
                    <h3 className="font-22 color-xlightgray text-center font-w500">SEMERKAND SEYİR KURUMSAL İLETİŞİM BİLGİLERİ</h3>
                    <div className="pt-50 help-content">

                        <p><b>Ticaret Unvanı:</b> Seyir Dijital Yayıncılık Anonim Şirketi</p>
                        <p><b>Ticaret Sicil Numarası:</b> 698869</p>
                        <p><b>Adres:</b> Eyüp Sultan Mahallesi Esma Sokak No: 3  Samandıra  Sancaktepe/İstanbul</p>
                        <p><b>Telefon:</b> 0216 564 25 00</p>
                        <p><b>E-Posta Adresi:</b> bilgi@semerkandseyir.com</p>
                        <p><b>Kep Adres:</b> seyirdijital@hs03.kep.tr</p>
                        <p><b>Vergi Numarası:</b> 614 034 4604</p>
                        <p><b>Mersis Numarası:</b> 0614-0344-6040-0013</p>
                    
                    </div>
                </section>
                <section className="mt-150"></section>
                                     
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default Corporate;