import React, { Component } from 'react'
import api from '../services/api';

import HomeShowcase from './HomeShowcase';
import HomeOngoing from './HomeOngoing';
import HomeContentGroup from './HomeContentGroup';
import HomeContentGroup2 from './HomeContentGroup2';
import Loader from './Loader';
//import HomeContentGroup3 from './HomeContentGroup3';
    
export default class HomeContentGroups extends Component {
    state = {
        isPageLoader: true,
        content_groups:[],            
    }
    //async componentDidMount() {
    async componentDidMount() {
        //try {
            var groups = localStorage.getItem('groups');
            var jsonData = JSON.parse(groups);
            if(groups === undefined || groups === null || groups === "" || jsonData.expireat < new Date().getTime()){
                api.get(window.apibaseurl + '/content/get_content_groups', {}, true)
                .then(resp=> {
                    var data = {}
                    var date = new Date();
                    data.expireat = date.getTime() + 15*60000
                    data.content = resp.data;
                    this.setState({
                        content_groups: data,
                        isPageLoader:false
                    })
                    localStorage.setItem('groups', JSON.stringify(data))
                })
            }
            else{
                this.setState({
                    isPageLoader:false,
                    content_groups: jsonData
                })
            }
        // } catch(err) {
        //     console.log("groups")
        //     console.log(err)
        // }
    }
    render() {
        return(
            this.state.isPageLoader
            ? <section className="container-box ptb-150 relative">
                <Loader />
            </section>
            : this.state.content_groups.content.map(function(content,index) {
                var type = index%4;
                if(index === 0) type = -1;
                switch(type){
                    case -1: return <div key={ "HomeShowcase" + index}><HomeShowcase/><HomeOngoing/></div>;
                    case 0: return <HomeContentGroup id={content.id} type={type} key={index} />;
                    case 1: return <HomeContentGroup id={content.id} type={type} key={index} />;
                    case 2: return <HomeContentGroup id={content.id} type={type} key={index} />;
                    case 3: return <HomeContentGroup2 id={content.id} type={type} key={index} />;
                    default: return <HomeContentGroup id={content.id} type={type} key={index} />
                }
               
            })
        )
    }
}