import React, { Component } from 'react'
import PropTypes from "prop-types";
// import VideoPlayer from "./VideoPlayer";
import WatchListButton from "./WatchListButton";
import {Link} from 'react-router-dom'


class Preview extends Component {
        componentDidMount = () =>{
           // it is a regular DOM node
           //console.log(this.props.videoUrl)
        }
        render() {
            // const videoJsOptions = {
            //     autoplay: false,
            //     controls: false,
            //     muted: true,
            //     showInfo: false,
            //     poster: this.props.thumb,
            //     sources: [{
            //       src: this.props.videoUrl,
            //       type: 'application/x-mpegURL'
            //     }]
            // }
            return (
                <div id={"video-preview-" + this.props.id} className="description">
                    <figure className="position-relative">
                        <Link to={this.props.detailUrl}><img src={this.props.thumb} className="responsive" alt=""/></Link>
                        
                        {/* <VideoPlayer { ...videoJsOptions } /> */}
                        {/* <div>
                        <video poster={this.props.thumb} controls autoPlay>
                            <source src={this.props.videoUrl} type="application/x-mpegURL" />
                        </video>
                        </div> */}
                    </figure>
                    <div className="description__text-container">
                        <span className="description__rating">{this.props.title}</span>
                        <span className="description__length">{this.props.duration}</span>
                    </div>
                    <div className="description__buttons-container">
                        <Link className="description__button" to={this.props.watchUrl}><i className="fa fa-play" aria-hidden="true"></i></Link>
                        
                        <div className="description__button">
                            <WatchListButton 
                                imagePath = {this.props.thumb} 
                                episodeId = {this.props.episodeId } 
                                id = {this.props.parentId} 
                                title = {this.props.title}
                            /> 
                        </div>
                        <Link className="description__button" to={this.props.detailUrl}><i className="fa fa-chevron-down" aria-hidden="true"></i></Link>
                    </div>
                </div>
            )
        }
}
Preview.propTypes = {
    title: PropTypes.string.isRequired,
    thumb: PropTypes.string.isRequired,
    detailUrl: PropTypes.string.isRequired
}
export default Preview;
