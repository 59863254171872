import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import HeaderSearch from "./HeaderSearch";
import TokenService from "../services/token.service";
import api from "../services/api";

export default class Header extends Component {
    state = {
        isLogin: false,
        shortName: "",
        redirect: false,
        user: {}        
    }
    componentDidMount (){
        //var logindata = localStorage.getItem('logindata');
        var loginUser = TokenService.getUser();
        if(loginUser === undefined  || loginUser === null || loginUser === "") return;

        var fullname = loginUser.user.name_surname;
        if(fullname === undefined || fullname === null || fullname === "") fullname = loginUser.user.name;
        var shortName = this.getShortName(fullname)
        this.setState({
            isLogin: true,
            shortName: shortName,
            user: loginUser
        })

        var watchdata = localStorage.getItem('my_watch_later_list');
        if(watchdata === undefined  || watchdata === null || watchdata === "") {
            api.get("/v2/content/get_my_watch_later_list", {}, true)
            .then(resp=> {
                var list = resp.data.map(item=> {
                    return item.id;
                
                    }    );
                localStorage.setItem('my_watch_later_list', JSON.stringify(list)); 
            })
            .catch(function (error) {
                console.log("watch list loaded")
            });

            //axios.get(window.apibaseurl + '/v2/content/get_my_watch_later_list', { headers: { 'X-Client-ID': window.clientId, 'X-Localization' : window.localization, 'Authorization': loginUser.token_type + " " + loginUser.token }})
            //.then(resp=> {
            //    var list = resp.data.map(item=> {
            //        return item.id;
            //    
            //        }    );
            //    localStorage.setItem('my_watch_later_list', JSON.stringify(list)); 
            //})
            //.catch(function (error) {
            //    console.log("watch list loaded")
            //});
        }
    }
    logout (e){
        e.preventDefault();
        TokenService.removeUser();
        window.location.href = "/";
    }
    getShortName(str){
        str = str
            // strip off capital letters (example: "First Last" ==> "FL")
        .replace(/[^A-Z]/g, "") 
            // append the second character of the first word to end of this new string  (example: "FL" ==> "FLI")
        .concat(str.charAt(1).toUpperCase()) 
            // limit this new string to 2 characters (example: "FLI" ==> "FL")
        .substring(0, 2); 
        return str;
      
    }
    render() {
        return (
            <header>
                <div className="container">
                <div className="logo">
                    <Link to="/"><img src="/Assets/images/logo.png" alt="Semerkan Seyir"/></Link>
                </div>
                <div className="top-menu hidden-m">
                    <a href="/category/22">Kültürel</a>
                    <a href="/category/21">Dini Programlar</a>
                    <a href="/category/25">Belgesel</a>
                    <a href="/category/27">Çocuk</a>

                    {/* <Link to="/category/22">Kültürel</Link>
                    <Link to="/category/21">Dini Programlar</Link>
                    <Link to="/category/25">Belgesel</Link>
                    <Link to="/category/27">Çocuk</Link> */}
                </div>
                {
                    this.state.isLogin 
                    ? <div className="fright pt-20 position-relative hidden-m">
                        <HeaderSearch/>
                        {/* <i className="fa fa-circle-o-notch fa-spin"></i> */}

                        <Link to="/account" className="user-menu">
                            {
                                this.state.shortName === ""
                                ? <i className='fa fa-user font-20'></i>
                                : this.state.shortName
                            }
                            </Link>
                        <nav className="user-drop-menu">
                            <div>
                                <Link to="/account">Hesabım</Link>
                                <Link to="/my-list">Listem</Link>
                                <Link to="/" onClick={this.logout}>Çıkış Yap</Link>
                            </div>
                        </nav>
                    </div>
                    : <div className="fright pt-20 hidden-m"><HeaderSearch/><Link to="/login" className="color-white display-ib mt-8"><i className="fa fa-sign-in"></i> Giriş Yap</Link></div>
                }
                
                </div>
            </header>
        )
    }
}
