import React, { Component } from 'react'
export default class HeaderSearch extends Component {
    state = {
        value: ""       
    }
    constructor(props) {
       super(props);
       this.state = {value:''}
 
       this.handleChange = this.handleChange.bind(this);
       this.keyPress = this.goToSearchResult.bind(this);
    } 
    componentDidMount (){
       
    }
    handleChange(e) {
        this.setState({ value: e.target.value });
    }
    goToSearchResult = (e) => {
        if (e.charCode === 13 || e.keyCode === 13) {
            e.preventDefault();
            window.location.href = "/search-result/" + this.state.value;
        }
    }
    render() {
        return (
            <form className="header-search-form">
                <div className="search-box">
                    <div className="btn-content">
                        <i className="fa fa-search" ></i>
                    </div>
                    <div className="btn-input">
                        <input type="text" id="search-input" maxLength="50" onKeyDown={this.goToSearchResult} onChange={this.handleChange} />
                    </div>
                </div>
            </form>
        )
    }
}
