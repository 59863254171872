import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from "./Footer"
import PageLoader from "./PageLoader";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import api from '../services/api';

const MySwal = withReactContent(Swal);

class Account extends Component {
    state = {
        isPageLoader: true,
        videos:[]            
    }
    componentDidMount(){
        api.get('/user/profile?timestamp=' + new Date().getTime(), {}, true)
        .then(resp=> {
            this.setState({
                isPageLoader:false,
                user: resp.data
            })
            
            console.log(resp.data)
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                    })
                }                
                else{
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
    render() {
        return (
            this.state.isPageLoader 
            ? <PageLoader />
            : <main className="clear-after overflow-hidden">
                <Header/>
                <section className="ptb-200 bg-xxlightgray color-themedark">
                    <div className="container">
                        <h2 className="font-24 mb-20">Üyelik Bilgilerim</h2>
                        <table className="table table-borderb font-16">
                            <colgroup>
                                <col className="width-200 bg-xlightgray"></col>
                                <col className="bg-white" style={{width:"auto"}}></col>
                                <col className="width-250 bg-xlightgray"></col>
                            </colgroup>
                            <tbody>
                                <tr className="display-tr">
                                    <td className="display-tc">İsim</td>
                                    <td className="display-tc">
                                        <span className="display-ib color-themedark font-w800">{this.state.user.name_surname}</span> 
                                    </td>
                                    <td>
                                        <Link to="/change-info" className="color-blue text-underline">İsim Değiştir</Link>
                                    </td>
                                </tr>
                                <tr className="display-tr">
                                    <td className="display-tc">E-Posta</td>
                                    <td className="display-tc">
                                        <span className="display-ib color-themedark font-w800">{this.state.user.email}</span> 
                                    </td>
                                    <td>
                                        <Link to="/change-mail" className="color-blue text-underline">E-posta Adresini Değiştir</Link>
                                    </td>
                                </tr>
                                <tr className="display-tr">
                                    <td className="display-tc">Telefon</td>
                                    <td className="display-tc">
                                        <span className="display-ib colo-gray">{this.state.user.phone}</span>
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                                <tr className="display-tr">
                                    <td className="display-tc">Şifre</td>
                                    <td className="display-tc">
                                        <span className="display-ib color-themedark font-w800">********</span> 
                                    </td>
                                    <td>
                                        <Link to="/change-password" className="color-blue text-underline">Şifreni Değiştir</Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default Account;