import axios from "axios";
import api from "./api";
import TokenService from "./token.service";

var clientId = 'eyJpdiI6IkE4WlpzZGR0Q1N5aUJsaDdZVzNVemc9PSIsInZhbHVlIjoiYk9sNkhKTFB1Ty80Z0pGZmNDbkNza3cwNnI5U1kyOVpDRSt3UVBDWXA5ND0iLCJtYWMiOiI1MjNmNDBhYjViZTg1YWZkYzczNjQ2NmNlMmVhZjhmZDNjMjM1M2U5NGFiNzQyMjcxZWMwMjcwZjFjOGQxODljIn0';
var parentId = 'eyJpdiI6InBaNk1KQjZPcUdVRTNuUmgzSHdUWXc9PSIsInZhbHVlIjoiNGNQS3A3WmgwaUJGQ0pMdmZkbExJUT09IiwibWFjIjoiMTc2NzFlN2M1MDNkYzRkMTQyZmYzN2YwYjdhOTJjMmVmNDFjNmFiZWM2YzRiNjA5YWRkZmE0YTc4OGU0NTkyMSJ9';


const register = (username, email, password) => {
  return api.post("/auth/signup", {
    username,
    email,
    password
  });
};
const login = (username, password, loginType) => {

  var data = {};
  data.client_id = clientId;
  data.parent_id = parentId;
  data.login_type = loginType;
  data.password = password;
  if(loginType === "phone"){ data.phone = username; }
  else { data.email = username; }
  return api.post("/auth/login", data);
};
const premiumControl = async (username) => {
  var url = "https://api.revenuecat.com/v1/subscribers/" + username;
  const headers = { Authorization: 'Bearer aCgAErTTpzzSJgOOrrvydIzjHRIRCnqp' }
  console.log(username)
  var hasPremium = false;
  await axios.get(url, { headers: headers })
      .then(resp=> {
          var response = resp.data;
          //if(response.subscriber.entitlements)
          var result = response.subscriber.entitlements
          if(Object.keys(result).length === 0) hasPremium = false;
          else hasPremium = true;
          console.log(result);
          //localStorage.setItem('my_watch_later_list', JSON.stringify(list)); 
      })
      .catch(function (error) {
          console.log("premium kontrolü yapılamadı!")
      });
      return hasPremium;
};
const logout = () => {
  TokenService.removeUser();
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  premiumControl
};
export default AuthService;