import React, { Component } from 'react'
import Loader from "./Loader";
import Header from "./Header";
import Footer from "./Footer";
import api from "../services/api";

export class MyList extends Component {
    state = {
        isLoading: true,
        videos: {}
    }
    componentDidMount (){
        api.get('/v2/content/get_my_watch_later_list', {})
        .then(resp=> {
            console.log(resp.data)
            this.setState({
                videos: resp.data,
                isLoading: false
            })
        })
    }
    render() {
        return (
        <main className="clear-after">
            <Header/>
            { 
                this.state.isLoading 
                ? <section className="container-box pt-100">
                <h3 className="font-22 color-white font-w500">Listem : <span className="font-18 font-w300 loading-dots">Liste yükleniyor</span></h3>
                    <div className="seperator seperator-darkgray mtb-20"></div>
                    <Loader />
                </section>
                : <section className="container-box pt-100">
                <h3 className="font-22 color-white font-w500">Listem</h3>
                <div className="seperator seperator-darkgray mtb-20"></div>
                <div className="row row-30 column-count-3 row-s-0 mtb-30">
                    {
                    this.state.videos.map(video => {
                        if(video.thumbnail != null){
                            return( 
                            <div className="col33 mb-60" key={video.id}>
                                <a className="display-b hover-zoom" href={"/detail/" + video.id}>
                                    <img alt="" className="responsive-image" src={video.thumbnail} />
                                    <span className="color-themelight font-16 font-w600 display-b mt-10">{video.title}</span>
                                </a>
                            </div>
                            )    
                        }
                        else{
                            return( 
                                <div className="col33 mb-60" key={video.id}>
                                    <div className="display-b">
                                        <img alt="" className="responsive-image" src={"https://via.placeholder.com/640x360.png?text=" + video.title} />
                                        <a className="color-themelight font-16 font-w500 display-b mt-10" href={"/detail/" + video.id}>{video.title}</a>
                                    </div>
                                </div>
                            )    
                        }
                        
                    })
                }
                    
                </div>
                </section>
            }
            <Footer isLogin={false}/>
        </main>
        )
    }
}
export default MyList