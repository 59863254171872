import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import WatchListButton from "./WatchListButton";
import api from "../services/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export class HomeShowcase extends Component {
  state = {
    isPageLoader: true,
    isOpen: false,
    seasonId: -1,
    watchlistButtonSending: false,
    video: { ...this.props },
  };
  componentDidMount() {
    try {
      var homediscover = localStorage.getItem("home_showcase");
      var jsonData = JSON.parse(homediscover);

      if (
        homediscover === undefined ||
        homediscover === null ||
        homediscover === "" ||
        jsonData.expireat < new Date().getTime()
      ) {
        api
          .get(window.apibaseurl + "/content/get_content_group/59", {}, true)
          .then((resp) => {
            var data = {};
            var contentLength = resp.data.content.length;
            const rand = Math.floor(Math.random() * contentLength);

            var date = new Date();
            data.expireat = date.getTime() + 15 * 60000;
            data.content = resp.data.content[rand];
            this.setState({
              isPageLoader: false,
              video: data.content,
            });
            localStorage.setItem("home_showcase", JSON.stringify(data));
          });
      } else {
        this.setState({
          isPageLoader: false,
          video: jsonData.content,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  addList = (e) => {
    console.log("ss");
    var logindata = localStorage.getItem("logindata");
    var userData = JSON.parse(logindata);

    if (userData == null) {
      MySwal.fire({
        html: <p>Kullanıcı girişi yapmalısınız.</p>,
        icon: "info",
        confirmButtonText: "Kapat",
      });
      return;
    }
    this.watchlistButtonSendingToggle();
    api
      .post(
        window.apibaseurl +
          "/v2/content/add_to_watch_later/" +
          this.state.video.id,
        {},
        true
      )
      .then((resp) => {
        console.log(resp.data);
        var video = { ...this.state.video };
        video.watchlistadded = true;
        this.setState({
          video: video,
          watchlistButtonSending: false,
        });

        var watchdata = localStorage.getItem("my_watch_later_list");
        var watchJsonData = JSON.parse(watchdata);
        watchJsonData.push(video.id);
        localStorage.setItem(
          "my_watch_later_list",
          JSON.stringify(watchJsonData)
        );
      })
      .catch(function (error) {
        this.setState({
          watchlistButtonSending: false,
        });
        if (error.response) {
          if (error.response.status === 400) {
            MySwal.fire({
              html: <p>{error.response.data.message}</p>,
              icon: "info",
              confirmButtonText: "Kapat",
            });
          }
        }
      });
  };
  removeList = (e) => {
    console.log("ss");
    var logindata = localStorage.getItem("logindata");
    var userData = JSON.parse(logindata);

    if (userData == null) {
      MySwal.fire({
        html: <p>Kullanıcı girişi yapmalısınız.</p>,
        icon: "info",
        confirmButtonText: "Kapat",
      });
      return;
    }
    this.watchlistButtonSendingToggle();
    api
      .post(
        window.apibaseurl +
          "/v2/content/remove_from_watch_later/" +
          this.state.video.id,
        {},
        true
      )
      .then((resp) => {
        console.log(resp.data);
        var video = { ...this.state.video };
        video.watchlistadded = false;
        this.setState({
          video: video,
          watchlistButtonSending: false,
        });

        var watchdata = localStorage.getItem("my_watch_later_list");
        var watchJsonData = JSON.parse(watchdata);
        watchJsonData = watchJsonData.filter(function (el) {
          return el !== video.id * 1;
        });
        localStorage.setItem(
          "my_watch_later_list",
          JSON.stringify(watchJsonData)
        );
      })
      .catch(function (error) {
        this.setState({
          watchlistButtonSending: false,
        });
        if (error.response) {
          if (error.response.status === 400) {
            MySwal.fire({
              html: <p>{error.response.data.message}</p>,
              icon: "info",
              confirmButtonText: "Kapat",
            });
          }
        }
      });
  };
  watchlistButtonSendingToggle = (e) => {
    var newStatus = !this.state.watchlistButtonSending;
    console.log(newStatus);
    this.setState({
      watchlistButtonSending: newStatus,
    });
  };
  render() {
    return this.state.isPageLoader ? (
      <section className="container-box ptb-150 relative">
        <Loader />
      </section>
    ) : (
      <section className="home-showcase">
        <div className="container-box">
          {this.state.video.video.length > 0 &&
          this.state.video.video[0].thumbnails["home_header"] !== "" ? (
            <img
              src={this.state.video.video[0].thumbnails["home_header"]}
              alt=""
              srcSet=""
            />
          ) : (
            <img
              src="https://via.placeholder.com/1920x1080.png?text=."
              alt=""
              srcSet=""
            />
          )}
        </div>

        <div className="home-showcase-content">
          <div className="display-t height-100p width-max">
            <div className="display-tc valign-middle height-100p">
              <div className="container-box home-showcase-detail">
                {this.state.video.is_original === 1 ? (
                  <h3 className="color-white font-26 font-m-20 font-s-16 font-w200">
                    <b className="font-w500">SEYİR</b> ORIGINAL
                  </h3>
                ) : (
                  ""
                )}
                <h1 className="color-white font-80 font-m-60 font-s-30 font-bold">
                  {this.state.video.title}
                </h1>
                <div className="clear-after">
                  <p
                    className="detail-description mt-20 font-16 font-s-14 col30 col75m col100s"
                    dangerouslySetInnerHTML={{
                      __html: this.state.video.description,
                    }}
                  ></p>
                  <div className="clear col30 col100m">
                    <div className="display-t font-12 color ptb-50 width-max">
                      <div className="display-tc valign-middle pr-10">
                        <Link
                          className="color-white"
                          to={"/watch/" + this.state.video.id + "/"}
                        >
                          <i className="fa fa-play-circle fa-3x valign-middle mr-5"></i>{" "}
                          Seyret
                        </Link>
                      </div>
                      {this.state.video.video.length > 0 &&
                      this.state.video.seasons.length > 0 &&
                      this.state.video.seasons[0].episodes.length > 0 ? (
                        <div className="display-tc text-center">
                          <WatchListButton
                            imagePath={
                              this.state.video.video[0].thumbnails["horizontal"]
                            }
                            episodeId={
                              this.state.video.seasons[0].episodes[0].id
                            }
                            id={this.state.video.id}
                            key={this.state.video.id}
                            title={this.state.video.title}
                          />
                        </div>
                      ) : null}
                      <div className="display-tc pl-10 text-right">
                        <Link
                          className="color-white detail-button"
                          to={"/detail/" + this.state.video.id}
                        >
                          <i className="fa fa-chevron-down valign-middle mr-5 cursor-pointer"></i>{" "}
                          Detay
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeShowcase;
