import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from "./Footer"
import { useForm } from "react-hook-form";
import api from "../services/api";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import TokenService from '../services/token.service';

const MySwal = withReactContent(Swal);

class ChangeMail extends Component {
    state = {
        isPageLoader: true,
        user:{}            
    }
    componentDidMount(){
        var user = TokenService.getUser();
        this.setState({
            user: user.user
        })
    }
    render() {
        return (
            <main className="clear-after overflow-hidden">
                <Header/>
                <section className="ptb-200 bg-xxlightgray color-themedark">
                    <div className="container bg-white p-50 border border-color-xlightgray border-width-2">
                        <h2 className="font-24 mb-20">E-Posta adresini Numarasını Güncelle</h2>
                        <p className="font-16 lineheight-18">
                        </p>
                        <ChangeMailForm user={this.state.user} />
                    </div>
                </section>
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default ChangeMail;


export function ChangeMailForm(props) {
    const [sendingForm, sendingToggle] = useState(false);
    const {register, handleSubmit, errors } = useForm();
   // const {register, handleSubmit, getValues, errors } = useForm();
    
    const onSubmit = data => {
        sendingToggle(true);
        
        var userData = TokenService.getUser();
        api.post(window.apibaseurl + '/user/change_email', data)
        .then(resp=> { 
            userData.email = data.email;
            TokenService.setUser(userData);

            MySwal.fire({
                html: <p>{resp.data.message}</p>,
                icon: 'success',
                confirmButtonText: 'Kapat'
            })
            sendingToggle(false);
        })
        .catch(function (error) {
            sendingToggle(false);
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
                else {
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
     
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="changepassword-form ptb-50" autoComplete="off">
            <div className="width-250">
                <div className="mtb-10">
                    <label className="form-label font-14">Yeni E-Posta Adresi</label>
                    <input type="text" id="email" name="email" defaultValue={props.user.email} className={"form-input form-input-large form-input-rounded" + (errors.current_password ? " error" : "")} 
                        ref={register({ 
                            required: true
                        })}
                    />
                </div>
                
                <div className="mtb-30">
                    <Link to="/account" className="font-w600 btn btn-white-o btn-rounded"><i className="fa fa-chevron-left"></i> Geri</Link>
                    <button type="submit" className={"font-w600 btn btn-black btn-rounded ml-30" + (sendingForm === true ? " sending": "")}>Güncelle</button>
                </div>
            </div>
        </form>
    );
  }