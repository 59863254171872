import React, { Component } from 'react'
import api from '../services/api';
import TokenService from '../services/token.service';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export class WatchListButton extends Component {
    state = {
        watchlistButtonSending: false,
        watchlistadded:false
    }
    constructor(props){
        super(props)
        this.addList = this.addList.bind(this);
        this.removeList = this.removeList.bind(this);
    }
    componentDidMount (){
        var watchdata = localStorage.getItem('my_watch_later_list');
        if(watchdata == null ||watchdata === undefined || watchdata === "") watchdata = "[]";
        var watchJsonData = JSON.parse(watchdata);
        var content_id = this.props.id;
        var isAddedWatchList = watchJsonData.filter(function (el) {
            return el === content_id*1;
        }).length > 0;
        this.setState({
            watchlistadded: isAddedWatchList,
        });
    }
    addList = (e) =>{
        var logindata = TokenService.getUser();        
        if(logindata == null){
            MySwal.fire({
                html: <p>Kullanıcı girişi yapmalısınız.</p>,
                icon: 'info',
                confirmButtonText: 'Kapat'
            })
            return;
        }
        this.watchlistButtonSendingToggle();
        
        var content_id = this.props.id;

        var postData = {};
        postData.episode_id = content_id;
        postData.title = this.props.title;
        postData.thumbnail = this.props.imagePath;
        api.post('/v2/content/add_to_watch_later/' + content_id, postData)
        .then(resp=> {
            //var video = {...this.state.video}
            //video.watchlistadded = true;
            this.setState({
                    watchlistButtonSending: false,
                    watchlistadded: true,
            });

            //var watchdata = localStorage.getItem('my_watch_later_list');
            //var watchJsonData = JSON.parse(watchdata);
            //watchJsonData.push(content_id);
            //localStorage.setItem('my_watch_later_list',JSON.stringify(watchJsonData));
        })
        .catch(function (error) {
            this.setState({
                    watchlistButtonSending: false
            });
            
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                    })
                }
            }
        });
    }
    removeList = (e) =>{
        var logindata = TokenService.getUser();

        if(logindata == null){
            MySwal.fire({
                html: <p>Kullanıcı girişi yapmalısınız.</p>,
                icon: 'info',
                confirmButtonText: 'Kapat'
            })
            return;
        }
        this.watchlistButtonSendingToggle();
        var content_id = this.props.id;
        api.post('/content/remove_from_watch_later/' + content_id, {})
        .then(resp=> {
           console.log(resp.data)
           this.setState({
                watchlistadded: false,
                watchlistButtonSending: false
           });

        //    var watchdata = localStorage.getItem('my_watch_later_list');
        //    var watchJsonData = JSON.parse(watchdata);
        //    watchJsonData = watchJsonData.filter(function (el) {
        //         return el !== content_id*1;
        //    })
        //    localStorage.setItem('my_watch_later_list',JSON.stringify(watchJsonData));
        })
        .catch(function (error) {
            this.setState({
                watchlistadded: false,
                watchlistButtonSending: false
            });
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
    watchlistButtonSendingToggle = (e) =>{
        var newStatus = !this.state.watchlistButtonSending;
        this.setState({
            watchlistButtonSending: newStatus
        });
    }
    render() {
        return (
            <div>
            {
                this.state.watchlistadded
                ? <span className={"remove-watch-list" + (this.state.watchlistButtonSending ? ' sending': '')} onClick={this.removeList.bind(this)}>
                <i className="fa fa-minus valign-middle mr-5"></i> Listeden Çıkar</span>   
                : <span className={"add-watch-list" + (this.state.watchlistButtonSending ? ' sending': '')} onClick={this.addList.bind(this)}>
                <i className="fa fa-plus valign-middle mr-5"></i> Listeye Ekle</span>   
            }
            </div>
        )
    }
}
export default WatchListButton
