import React, { Component } from 'react'
import Header from "./Header"
import HomeFaq from './HomeFaq';
import Footer from "./Footer"

class Faq extends Component {
    render() {
        return (
            <main className="clear-after">
                <Header/>
                
                <HomeFaq/>
                <section className="mt-150"></section>
                                     
                <Footer isLogin={false}/>
            </main>
        )
    }
}
export default Faq;