import React, { Component } from 'react'
import Header from './Header'
import Footer from "./Footer"
import WatchListButton from "./WatchListButton"
import SquareImageViewer from "./SquareImageViewer";
import PageLoader from "./PageLoader";
import { Link } from 'react-router-dom';
import api from '../services/api';

let cid;
export class Category extends Component {
    state = {
        isLoading: true,
        watchlistadded: false,
        watchlistButtonSending: false,
        content: {}        
    }
    componentDidMount (){
        console.log("hop")
        console.log(this.props)
        cid = window.location.pathname.split('/')[2];
        this.showContent(cid)        
    }
    componentWillReceiveProps (nextProps){
        console.log("hey")
        cid = window.location.pathname.split('/')[2];
        this.showContent(cid)
    }
    componentDidUpdate(prevProps) {
        console.log("hey2")
        //if(prevProps.match.params.cid !== this.props.match.params.cid){
        //  // do something
        //    
        //}
    }
    showContent(cid) {
        this.setState({
            isLoading: true
        })
        api.get(window.apibaseurl + '/content/get_content_group/' + cid, {}, true)
        .then(resp=> {
            var watchdata = localStorage.getItem('my_watch_later_list');
            if(watchdata == null ||watchdata === undefined || watchdata === "") watchdata = "[]";
            var watchJsonData = JSON.parse(watchdata);
        
            var content_id = resp.data.content[0].id;
            var isAddedWatchList = watchJsonData.filter(function (el) {
                return el === content_id*1;
                }).length > 0;
            this.setState({
                content: resp.data,
                watchlistadded: isAddedWatchList,
                isLoading: false
            })
        })
    }   
    render() {
        return (
            this.state.isLoading 
            ? <PageLoader />
            : <main className="clear-after">
                <Header/>
                <section className="home-showcase">
                <div className="container-box">
                {
                    this.state.content.content[0].video[0].thumbnails["home_cover"] !== "" 
                    ? <img src={this.state.content.content[0].video[0].thumbnails["home_cover"]} alt="" srcSet="" />
                    : <img src="https://via.placeholder.com/1920x1080.png?text=." alt="" srcSet="" />
                }
                </div>
                
                <div className="position-absolute left-0 right-0 top-0 bottom-0 zindex-50">
                    <div className="display-t height-100p width-max">
                        <div className="display-tc valign-middle height-100p">
                            <div className="container-box">
                                <h3 className="color-white font-26 font-w400">{this.state.content.content[0].content_types.map(item => {return( item + " ")})}</h3>
                                <h1 className="color-white font-80 font-bold text-shadow">{this.state.content.content[0].title}</h1>
                                
                                <div className="tags">
                                    <span>{this.state.content.content[0].content_types[0]}</span>
                                    {this.state.content.content[0].mood.map(function(el,index) {
                                        return <Link to={"/search-result/" + el} key={index}>{el} </Link>
                                    })}
                                </div>
                                <div className="clear-after">
                                    <p className="color-white font-16 font-w400 mt-20 col50 col75m col100s" dangerouslySetInnerHTML={{__html: this.state.content.content[0].video[0].description }}></p>
                                    <div className="clear col40">
                                        <div className="display-t font-12 color ptb-50 width-max">
                                            <div className="display-tc valign-middle pr-10">
                                                {
                                                    this.state.content.content.length > 0 
                                                    && this.state.content.content[0].seasons.length > 0 
                                                    && this.state.content.content[0].seasons[0].episodes.length > 0
                                                    ? <Link className="color-white" to={'/watch/' +  this.state.content.content[0].seasons[0].episodes[0].id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>
                                                    : ""
                                                }
                                                {/* {
                                                    this.state.video.content_type === "movie"
                                                    ? <Link className="color-white" to={'/watch/' +  this.state.video.id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                    : <Link className="color-white" to={'/watch/' +  this.state.video.seasons[0].episodes[0].id + '/'}><i className="fa fa-play-circle fa-3x valign-middle mr-5"></i> Seyret</Link>    
                                                } */}
                                            </div>
                                            {
                                                    this.state.content.content[0].video.length > 0
                                                    && this.state.content.content[0].seasons.length > 0
                                                    && this.state.content.content[0].seasons[0].episodes.length > 0
                                                    ? <div className="display-tc text-center">
                                                        <WatchListButton 
                                                            imagePath = {this.state.content.content[0].video[0].thumbnails["horizontal"]} 
                                                            episodeId = {this.state.content.content[0].seasons[0].episodes[0].id } 
                                                            id = {this.state.content.content[0].id} 
                                                            title = {this.state.content.content[0].title}
                                                        /> 
                                                    </div>
                                                    : null
                                            }
                                            <div className="display-tc pl-10 text-right">
                                                <Link className="color-white detail-button" to={'/detail/' +  this.state.content.id + '/'} ><i className="fa fa-chevron-down valign-middle mr-5 cursor-pointer"></i> Detay</Link>  
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                <section className="">
                <div className="pb-100 clear-after">
                    <div className="episodes">
                        <MySwiper content={this.state.content} />
                    </div>
                </div>
                </section>
                <Footer isLogin={false}/>
            </main>  
        )
    }
}
export default Category

export function MySwiper(props) {
    return (
        <section className="row row-20">
        {
            props.content.content.map(function(content,index) {
                var parent_id = content.parent_id;
                if(parent_id === undefined || parent_id === null){ 
                    parent_id = content.id;
                }
                const rand = 10000 + Math.random() * (99999 - 10000);
                if(content.video.length > 0){
                    return <div className="col20 pb-20" key={index}>
                        <SquareImageViewer   
                            rid = {rand}
                            parentId = {parent_id}                                          
                            id={content.id}
                            title={content.title ?? ""}
                            thumb={content.video[0].thumbnails["horizontal"]}
                            //thumb={"https://via.placeholder.com/640x360.png?text=" + content.title}
                            videoUrl={content.video[0].url}
                            detailUrl= {'/detail/' + content.id}
                            preview={false}
                        />
                    </div>
                }
                return <div className="col20 pb-20" key={index}>
                        <SquareImageViewer 
                            rid = {rand}
                            parentId = {parent_id}         
                            id={content.id}
                            title={ "[" + content.title + "]"}
                            thumb={"https://via.placeholder.com/733x446.png?text=" + content.title}
                            videoUrl={""}
                            detailUrl= {'/detail/' + content.id}
                            preview={false}
                        />
                </div>
            })
        }
        </section>
    )
}