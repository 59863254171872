import React, { Component } from 'react'
import Modal from "react-modal";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
  } from "react-share";
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '350px',
      minHeight: '200px',
      width: '100%',
    },
  };
export class ShareModal extends Component {
    state = {
        isOpen: false,
    }
    toggleModal = (e)=>{
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        return (
            <div>
                <span className='cursor-pointer' onClick={this.toggleModal}>{this.props.children}</span>
                <Modal
                    isOpen={this.state.isOpen}
                    onRequestClose={this.toggleModal}
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    style={customStyles}
                >
                    <div className="share-modal">
                        <h3>Arkadaşlarınızla Paylaşın</h3>
                        <FacebookShareButton url={ this.props.shareUrl } ><i className="fa fa-facebook"></i></FacebookShareButton>
                        <TwitterShareButton  url={ this.props.shareUrl } title={ this.props.shareText }><i className="fa fa-twitter"></i></TwitterShareButton>
                        <LinkedinShareButton url={ this.props.shareUrl } title={ this.props.shareText } ><i className="fa fa-linkedin"></i></LinkedinShareButton>
                        <WhatsappShareButton url={ this.props.shareUrl } title={ this.props.shareText } ><i className="fa fa-whatsapp"></i></WhatsappShareButton>
                        <TelegramShareButton url={ this.props.shareUrl } title={ this.props.shareText } ><i className="fa fa-telegram"></i></TelegramShareButton>
                        
                        <button className="close-share-modal-button" onClick={this.toggleModal}>Kapat</button>
                    </div>
                </Modal>
            </div>
            
        )
    }
}
export default ShareModal
