import axios from "axios";
import qs from 'qs'; 

const control = (phone) => {
  const pdata = qs.stringify({
    username: phone,
  });
  const headers = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  };
  return axios.post("https://panel.edergilik.com/api/auth/subscriberbyusername", pdata, headers );
};
const PremiumService = {
  control
};
export default PremiumService;