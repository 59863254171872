import React, { Component } from 'react'
import Footer from './Footer';
import Header from './Header';
class TermsOfUse extends Component {
    
    render() {
        return (
            <main className="clear-after overflow-hidden pt-150 mt-s-100">
                <Header/>
                <div className="container html-content font-16 lineheight-15">

                    <h1 className="font-18 font-w600 mb-20">SEMERKAND SEYİR KULLANIM ŞARTLARI</h1>


                    <p>1. Giriş</p>
                    <p>2. Tarafımızca Sunulan Semerkand Seyir Hizmeti.</p>
                    <p>3. Semerkand Seyir Hizmeti'ni Kullanımınız</p>
                    <p>4. içerik ve Fikri Mülkiyet Hakları</p>
                    <p>5. Müşteri Desteği, Bilgi, Sorular ve şikayetler</p>
                    <p>6. Sorunlar ve Anlaşmazlıklar</p>
                    <p>7. Bu şartlar Hakkında</p>
                    
                    <h2 className="mt-20 mb-10">1. Giriş</h2>

                    <p>Bu şartlar'ı içeren veya bu şartlar ile bağlantılı tüm web sitelerimiz ve yazılım uygulamalarımız (toplu olarak "Semerkand Seyir Hizmeti") ve Semerkand Seyir Hizmeti aracılığıyla kullanıma sunulan her türlü müzik, video veya diğer materyal ("içerik") dahil olmak üzere video ve başka içeriklerin akışı için Semerkand Seyir'in kişiselleştirilmiş hizmetlerini kullanımınız (bunlara erişim dahil) bu şartlar'a tabiolacağından lütfen bu Kullanım şartları'nı ("şartlar") dikkatli bir şekilde okuyun.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmeti'ninin kullanımı, Semerkand Seyir tarafından sunulacak ve bu şartlar'a referans yoluyla ilave edilecek ek şartlara veya koşullara tabi olabilir.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmeti'ne
                        kaydolarak veya başka bir şekilde kullanarak bu şartlar'ı
                        kabul edersiniz. Bu şartlar'ı kabul etmiyorsanız
                        Semerkand Seyir Hizmeti'ni kullanmamalı veya herhangi
                        bir içerik'e erişmemelisiniz.</p>

                    <p>&nbsp;</p>

                    <p>Hizmet Sağlayıcı</p>

                    <p>&nbsp;</p>

                    <p>Bu şartlar siz ve Semerkand Seyir (Eyüp Sultan Mah. Esma Sk.
                            No:7/A 34885 Samandıra Sancaktepe - İSTANBUL /
                            TÜRKıYE
                            arasındadır.</p>

                    <p>&nbsp;</p>

                    <p>Yaş ve Uygunluk Koşulları</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmeti'ni kullanabilmek ve herhangi bir içerik'e erişebilmek için (1) 13 yaşında (veya kendi
                        ülkenizdeki eş değer asgari yaşta) veya daha büyük olmanız; (2) kendi ülkenizde
                        yasal sorumluluk yaşının altındaysanız veliniz veya vasinizden izin almış
                        olmanız; (3) bizimle bağlayıcı bir sözleşme imzalama yetkisine sahip olmanız ve
                        geçerli kanunlar kapsamında bu yönde bir engelinizin olmaması ve (4) Hizmet'in kullanılabilir olduğu bir ülkede ikamet etmeniz
                        gerekmektedir. Ayrıca, Semerkand Seyir'e
                        gönderdiğiniz her türlü kayıt bilgisinin gerçek, doğru, eksiksiz olduğuna ve bu
                        bilgileri her zaman bu şekilde tutmayı kabul ettiğinize dair söz verirsiniz.
                        Kendi ülkenizde yasal sorumluluk yaşının altındaysanız bu şartlar'ı
                        sizin adınıza veliniz veya vasiniz imzalamalıdır. Asgari yaş gereksinimlerine
                        ilişkin ek bilgilere kayıt sürecinde ulaşabilirsiniz. Asgari yaş
                        gereksinimlerini karşılamıyorsanız Semerkand Seyir sizi bir kullanıcı olarak
                        kaydedemez.</p>

                    <p>&nbsp;</p>

                    <p>2. Tarafımızca Sunulan Semerkand Seyir
                        Hizmeti.</p>

                    <p>Semerkand Seyir Hizmeti Seçenekleri</p>

                    <p>Çok sayıda Semerkand Seyir Hizmeti seçeneği
                        sunarız. Bazı Semerkand Seyir Hizmeti seçenekleri
                            ücretsiz sunulurken bazı seçenekler içinse erişim öncesinde ödeme yapılması
                            gerekir ("Ücretli Abonelikler"). Bunların yanı sıra üçüncü
                        taraf ürünlerinin ve hizmetlerinin sunulması dahil, özel promosyon planları,
                        abonelikler veya hizmetler de sunabiliriz. Bu tür üçüncü tarafların sağladığı
                        ürünler ve hizmetler bizim sorumluluğumuzda değildir.</p>

                    <p>&nbsp;</p>

                    <p>Sınırsız Hizmet, tüm kullanıcılara
                        sunulmayabilir. Hizmetler için kaydolduğunuzda hangi
                            hizmetlerin tarafınızca kullanılabileceğini açıklarız. Sınırsız Hizmet
                            aboneliğinizi iptal ederseniz ya da Sınırsız Hizmet aboneliğiniz kesintiye
                            uğrarsa (ör. ödeme bilgilerinizi değiştirmeniz durumunda) Sınırsız Hizmet için
                            tekrar abone olamazsınız. Sınırsız Hizmet'in gelecekte
                            kaldırılabileceğini unutmayın. Bu durumda Hizmet için artık sizden ücret
                            alınmaz.</p>

                    <p>&nbsp;</p>

                    <p>Deneme Sürümleri</p>

                    <p>Muhtelif zamanlarda, bizim
                        tarafımızdan ya da bizim adımıza başkaları tarafından Ücretli Abonelikler'in deneme sürümleri belirli bir süreliğine
                        ödeme yapılmaksızın ya da indirimli bir fiyattan sunulabilir ("Deneme
                        Sürümü"). Deneme Sürümü aracılığıyla bir Semerkand Seyir Hizmeti
                        kullanarak Semerkand Seyir Premium Promosyon Teklifi şartları'nı
                        kabul etmiş sayılırsınız.</p>

                    <p>&nbsp;</p>

                    <p>Üçüncü Taraf Uygulamaları, Cihazlar ve Açık
                        Kaynak Yazılımı</p>

                    <p>Semerkand Seyir Hizmeti; üçüncü taraf
                        uygulamaları, web siteleri ve hizmetlerinin ("Üçüncü Taraf
                        Uygulamaları") yanı sıra üçüncü taraf kişisel bilgisayarları, mobil
                        telefonları, tabletleri, giyilebilir cihazları ve diğer cihazları ("Cihazlar")
                        ile entegre edilebilir veya bunlarla başka şekilde etkileşime geçebilir. Söz
                        konusu Üçüncü Taraf Uygulamaları ve Cihazları'nı
                        kullanmanız, tarafınıza ilgili üçüncü tarafça sunulacak ek şartlara, koşullara
                        ve politikalara tabi olabilir. Semerkand Seyir, Üçüncü Taraf Uygulamaları ve Cihazları'nın Semerkand Seyir Hizmeti ile uyumlu olacağını
                        garanti etmez.</p>

                    <p>&nbsp;</p>

                    <p>Hizmet Sınırlamaları ve Değişiklikleri</p>

                    <p>Semerkand Seyir Hizmeti'ni
                        faal durumda tutmak ve size kişiselleştirilmiş, sürükleyici bir video deneyimi
                        sunmak için makul özeni gösteriyoruz. Ancak hizmet sunumlarımız ve bunların
                        kullanılabilirlikleri zaman zaman ve geçerli kanunlara tabi olmak üzere, size
                        karşı hiçbir yükümlülük olmaksızın değişebilir; örnek:</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmetleri'nde teknik sorunlar,
                        bakım veya test işlemleri ya da ilgili kanunlardaki ve düzenleme
                        gerekliliklerindeki değişiklikleri yansıtmak için yapılması gerekenler dahil
                        olmak üzere güncellemeler nedeniyle geçici kesintiler söz konusu olabilir.</p>

                    <p>Hizmetler'i sürekli geliştirmeyi ve iyileştirmeyi
                        amaçlıyoruz ve Semerkand Seyir Hizmeti'nin tamamını
                        veya bir kısmını değiştirebilir, askıya alabilir ya da (belirli işlevler,
                        özellikler, abonelik planları ve promosyon teklifleri dahil) sağlamayı
                        durdurabiliriz (geçici veya kalıcı olarak).</p>

                    <p>Semerkand Seyir, Semerkand Seyir Hizmeti ile
                        herhangi bir belirli içeriği sunmakla yükümlü değildir ve Semerkand Seyir ya da
                        ilgili sahipler belirli dizi, film, belgesel, program, video ve diğer içerikler'i önceden bildirimde bulunmaksızın kaldırabilir.</p>

                    <p>Semerkand Seyir'ın Ön Ödemeli Döneminiz (bu terim, aşağıdaki Ödemeler ve
                        ıptaller bölümünde tanımlanmıştır) sona ermeden sonlandırdığı bir Ücretli
                        Abonelik ile ilgili olarak doğrudan Semerkand Seyir’e
                        önceden ödediğiniz tutarlar olması durumunda Semerkand Seyir, ilgili
                        sonlandırma işlemine müteakip mevcut Ücretli Aboneliğinizin kullanılmayan kısmı
                        için Ön Ödemeli Dönem'e ilişkin önceden ödenmiş
                        ücretleri tarafınıza iade edecektir. ıadeyi gerçekleştirebilmemiz için
                        hesabınız ve fatura bilgileriniz güncel olmalıdır.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir, resmi makamların ve diğer
                        üçüncü tarafların eylemlerinden ve kontrolümüz dışındaki durumlardan
                        kaynaklanan internet veya diğer hizmet kesintileri ya da arızalarıyla
                        bağlantılı olarak tarafınıza herhangi bir iade gerçekleştirmekle yükümlü
                        değildir.</p>

                    <p>&nbsp;</p>

                    <p>3. Semerkand Seyir Hizmeti'ni
                        Kullanımınız</p>

                    <p>Semerkand Seyir hesabı oluşturma</p>

                    <p>Semerkand Seyir Hizmeti'nin
                        tamamını veya bir kısmını kullanabilmek için bir Semerkand Seyir hesabı
                        oluşturmanız gerekebilir. Kullanıcı adınız ve parolanız kişisel kullanımınıza
                        özeldir ve gizli tutulmalıdır. Kullanıcı adınızın ve parolanızın her türlü
                        kullanımından (her türlü yetkisiz kullanım dahil) sizin sorumlu olduğunuzu
                        anlarsınız. Kullanıcı adınız veya parolanız kaybolur ya da çalınırsa veya
                        hesabınıza yetkisiz bir giriş olduğunu düşünüyorsanız hemen Müşteri Hizmetleri
                        ekibimizi bilgilendirin.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir herhangi bir gerekçeyle
                        kullanıcı adınızı geri alabilir veya değiştirmenizi gerekli kılabilir.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmeti'ni
                        kullanma haklarınız</p>

                    <p>Semerkand Seyir Hizmetleri'ne Erişim</p>

                    <p>&nbsp;</p>

                    <p>Bu şartlar'a
                        (diğer geçerli şartlar ve koşullar dahil) uyumunuza tabi olmak üzere, Semerkand
                        Seyir Hizmeti'ni ve içerik'i
                        kişisel, ticari olmayan şekilde kullanmanız için size sınırlı, münhasır olmayan,
                        iptal edilebilir bir izin (toplu olarak "Erişim") veririz. Bu Erişim,
                        sizin tarafınızdan ya da Semerkand Seyir tarafından sonlandırılmadıkça ve
                        sonlandırılana kadar yürürlükte kalacaktır. Semerkand Seyir Hizmeti'ni
                        veya içerik'i yeniden dağıtmayacağınızı ya da
                        devretmeyeceğinizi kabul edersiniz.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir yazılım uygulamaları ve içerik
                        size lisanslanmamakta, satılmamakta ya da devredilmemektedir ve Cihazlarınıza
                        yüklendikten sonra bile Semerkand Seyir yazılım uygulamalarının ve içerik'in tüm kopyalarının mülkiyeti Semerkand Seyir ve
                        lisans sağlayıcılarında kalır.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir'ın
                        Mülkiyet Hakları</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmeti ve içerik, Semerkand Seyir'in veya Semerkand Seyir'in
                        lisans sağlayıcılarının mülkiyetindedir. Tüm Semerkand Seyir ticari markaları,
                        hizmet markaları, ticari adları, logoları, alan adları ve Semerkand Seyir
                        markasının diğer tüm özellikleri ("Semerkand Seyir Marka
                        Özellikleri") yalnızca Semerkand Seyir'ın veya
                        lisans sağlayıcılarının mülkiyetindedir. Bu şartlar size hiçbir Semerkand Seyir
                        Marka Özelliği'ni ticari veya ticari olmayan
                        kullanımlar için kullanma hakkı vermemektedir.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Kullanıcı ılkeleri'ne uymayı
                        ve Semerkand Seyir Hizmeti'ni, içerik'i
                        ya da bunların herhangi bir bölümünü şartlar'da
                        açıkça izin verilmemiş hiçbir biçimde kullanmamayı kabul edersiniz.</p>

                    <p>&nbsp;</p>

                    <p>ÖDEMELER VE ıPTAL</p>

                    <p>&nbsp;</p>

                    <p>Faturalandırma</p>

                    <p>&nbsp;</p>

                    <p>Aşağıdaki yöntemlerle doğrudan Semerkand Seyir'den ya da bir üçüncü taraf aracılığıyla Ücretli
                        Abonelik satın alabilirsiniz:</p>

                    <p>&nbsp;</p>

                    <p>&#9679; Aylık olarak ya da
                        satın alımınızdan önce size açıklanan başka bir düzenli aralıkta peşin abonelik
                        ücreti ödeyerek veya</p>

                    <p>&nbsp;</p>

                    <p>&#9679; Belirli bir
                        süreliğine ("Ön Ödemeli Dönem") Semerkand Seyir Hizmeti'ne
                        erişmenizi sağlayan ön ödemeyle.</p>

                    <p>&nbsp;</p>

                    <p>Vergi oranları, sunduğunuz
                        bilgiler ve aylık ücretlendirmeniz sırasında geçerli olan oran baz alınarak
                        hesaplanır.</p>

                    <p>&nbsp;</p>

                    <p>Bir üçüncü taraf aracılığıyla Ücretli Abonelik
                        erişimi satın alırsanız bu şartlar'ın yanı sıra
                        Semerkand Seyir Hizmeti'ni kullanımınıza ilişkin
                        olarak ilgili üçüncü tarafın ayrı şartları ve koşulları geçerli olabilir.
                        Ücretli Abonelik'i Semerkand Seyir tarafından ya da
                        Semerkand Seyir adına sağlanan veya satılan bir kod, hediye kartı, ön ödemeli
                        teklif ya da başka bir teklifle ("Kodlar") satın alırsanız Semerkand
                        Seyir Kart şartları'nı kabul etmiş olursunuz.</p>

                    <p>&nbsp;</p>

                    <p>Fiyat ve vergi değişiklikleri</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir; düzenli abonelik ücretleri,
                        Ön Ödeme Dönemi (henüz ödemesi yapılmamış dönemler
                        için) ya da Kodlar (yukarıda tanımlanmıştır) dahil Ücretli Abonelikler'in
                        ücretini zaman zaman değiştirebilir ve her türlü fiyat değişikliğini makul bir
                        süre önce size bildirecektir. Fiyat değişiklikleri, fiyat değişikliği tarihini
                        takip eden bir sonraki abonelik döneminin başında yürürlüğe girer. Geçerli
                        kanunlara tabi olmak üzere, fiyat değişikliği geçerli olduktan sonra Semerkand
                        Seyir Hizmeti'ni kullanmaya devam ederek yeni fiyatı
                        kabul etmiş olursunuz. Bir fiyat değişikliğini kabul etmiyorsanız fiyat
                        değişikliği yürürlüğe girmeden önce geçerli Ücretli Abonelik'ten
                        çıkarak değişikliği reddedebilirsiniz.</p>

                    <p>&nbsp;</p>

                    <p>Vergi oranları, aylık
                        ücretlendirmeniz sırasında geçerli olan oranları baz alır. Bu tutarlar
                        ülkenizdeki, eyaletinizdeki hatta şehrinizdeki vergi gerekliliklerine göre
                        zamanla değişebilir. Vergi oranlarındaki her türlü değişiklik, sunduğunuz hesap
                        bilgilerine göre otomatik olarak uygulanır</p>

                    <p>&nbsp;</p>

                    <p>Yenileme ve ıptal</p>

                    <p>Bir Ön Ödemeli Dönem için Ücretli Abonelik
                        hariç olmak üzere, Semerkand Seyir'e veya Ücretli Abonelik'i aldığınız üçüncü tarafa yapacağınız ödeme, Ücretli
                        Aboneliğinizi o sırada geçerli olan abonelik döneminin bitiminden önce iptal
                        etmediğiniz sürece ilgili abonelik döneminin sonunda otomatik olarak yenilenir.
                        Nasıl iptal edileceğine ilişkin talimatlar için Müşteri Desteği ekibimizle
                        buradan iletişime geçebilirsiniz. ıptal işlemi o anki abonelik döneminin nihai
                        gününden sonraki gün yürürlüğe girer.</p>

                    <p>&nbsp;</p>

                    <p>Bir Kod kullanarak Ücretli
                        Abonelik satın aldıysanız aboneliğiniz, Kod'da
                        belirtilen sürenin sonunda ya da Semerkand Seyir Hizmeti için ödenmesi gereken
                        ön ödeme bakiyesi yetersiz olduğunda aboneliğiniz otomatik olarak
                        sonlandırılır.</p>

                    <p>&nbsp;</p>

                    <p>Cayma hakkı</p>

                    <p>&nbsp;</p>

                    <p>Deneme Sürümü için üye olursanız
                        Deneme Sürümü aldığınız Ücretli Abonelik için cayma hakkının Deneme Sürümü'nü başlatmanızdan on dört (14) gün sonra sona
                        ereceğini kabul edersiniz. Deneme Sürümü sona ermeden önce Ücretli Abonelik'i iptal etmezseniz cayma hakkınızı kaybedersiniz
                        ve siz Ücretli Abonelik'i iptal edene kadar
                        kararlaştırılan tutarı size her ay otomatik olarak yansıtması için Semerkand Seyir'İ yetkilendirmiş olursunuz. On dört (14) günün
                        altındaki deneme süreleri için; Deneme Sürümünüzün sona ermesinden hemen sonra
                        size ücretli hizmeti sunacağımızı ve bu noktadan sonra cayma hakkınızı
                        kaybedeceğinizi açıkça kabul edersiniz.</p>

                    <p>&nbsp;</p>

                    <p>Ücretli Abonelik satın aldığınızda herhangi
                        bir gerekçeyle cayma hakkınızı kullanmak için on dört
                            (14) gününüzün olduğunu ve fikrinizi değiştirdiğinizi bildirene kadar
                        sunulan hizmetler karşılığında tarafımıza ödeme yapmanız gerektiğini kabul
                        edersiniz. Satın alma işleminizin hemen sonrasında tarafınıza hizmeti
                        sağlayacağımızı, cayma hakkınızı kaybedeceğinizi ve Semerkand Seyir'e siz iptal edene kadar her
                                ay sizden otomatik olarak ücret tahsil etme yetkisi verdiğinizi açıkça
                                onaylarsınız.</p>

                    <p>&nbsp;</p>

                    <p>Kullanıcı ılkeleri</p>

                    <p>Semerkand Seyir'ı
                        herkesin keyifle kullanmaya devam edebilmesi amacıyla Semerkand Seyir Hizmeti'ni kullanmanıza ilişkin ilkeler ("Semerkand
                        Seyir Kullanıcı ılkeleri") belirledik. Semerkand Seyir Hizmeti'ni
                        kullanırken Semerkand Seyir Kullanıcı ılkeleri'nin yanı sıra yürürlükteki tüm
                        kanunlara, kurallara ve yönetmeliklere uymanız ve üçüncü tarafların fikri
                        mülkiyetine, gizliliğine ve diğer haklarına riayet etmelisiniz.</p>

                    <p>&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>İhracat Kontrolü ve Yaptırımlar</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir'in ürünleri, ABD Ticaret Bakanlığı tarafından
                        uygulanan ihracat Yönetimi Düzenlemeleri ("EAR"), Hazine
                        Bakanlığı'nın Yabancı Varlıkların Kontrolü Ofisi'nin ("OFAC")
                        uyguladığı ticari ve ekonomik yaptırımlar ve Dış ışleri Bakanlığı'nın Uluslararası
                        Silah Kaçakçılığı Yönetmeliği ("ITAR") dahil olmak üzere ABD ihracat
                        ve yeniden ihracat kontrolü kanun ve düzenlemelerine ya da başka yargı
                        bölgelerinde geçerli benzer kanunlara tabi olabilir. (1) Amerika Birleşik
                        Devletleri'nin mallara ambargo uyguladığı ya da başka herhangi bir ekonomik
                        yaptırım getirdiği herhangi bir ülkede bulunmadığınızı ve (2) herhangi bir
                        geçerli ihracat ya da yeniden ihracat kanunlarında veya düzenlemelerinde ya da
                        başka yargı bölgelerinde geçerli benzer kanunlarda
                        belirtilen veya ABD hükümetinin yasaklı ya da sınırlandırılmış taraflar
                        listesinde yer alan yasaklı bir taraf olmadığınızı garanti edersiniz.</p>

                    <p>&nbsp;</p>

                    <p>Sınırlama olmaksızın EAR,
                        OFAC tarafından uygulanan ticari ve ekonomik yaptırımlar ve ITAR dahil geçerli
                        tüm ihracat ve yeniden ihracat kontrolü kanun ve düzenlemelerine uymayı kabul
                        edersiniz. Özellikle, bu şartlar kapsamında Semerkand Seyir'dan
                        alınan hiçbir ürünü, yazılımı veya teknolojiyi (bu tür teknolojilerden
                        türetilen ya da bunlara dayalı olan ürünler dahil), ilgili kanunların ve düzenlemelerin
                        gerektirdiği şekilde yetkili resmi makamlardan önceden gerekli izni almaksızın
                        kullanmayacağınızı ve Amerika Birleşik Devletleri'nde ya da başka herhangi bir
                        yargı bölgesinde EAR, OFAC tarafından uygulanan ticari ve ekonomik yaptırımlar
                        veya geçerli herhangi bir kanunla ya da düzenlemeyle yasaklanmış olan hiçbir
                        yere, kuruluşa veya kişiye hiçbir nihai kullanım için doğrudan ya da dolaylı
                        olarak satmayacağınızı, ihraç etmeyeceğinizi, yeniden ihraç etmeyeceğinizi,
                        aktarmayacağınızı, yönlendirmeyeceğinizi, yayınlamayacağınızı ya da başka bir
                        şekilde dağıtmayacağınızı kabul edersiniz.</p>

                    <p>&nbsp;</p>

                    <p>4. içerik ve Fikri Mülkiyet Hakları</p>

                    <p>&nbsp;</p>

                    <p>&nbsp;</p>

                    <p>Geri Bildirim</p>

                    <p>Semerkand Seyir Hizmeti veya
                        herhangi bir içerik ile bağlantılı olarak geri bildirim, fikir veya öneri
                        ("Geri Bildirim") sunmanız halinde ilgili Geri Bildirim gizli
                        olmayacaktır ve Semerkand Seyir bu Geri Bildirimi
                        sınırlama olmaksızın ve size ödeme yapmaksızın kullanabilir. Bu şartlar
                        kapsamında Geri Bildirim, bir tür Kullanıcı ıçeriği olarak düşünülür.</p>

                    <p>&nbsp;</p>

                    <p>Cihazınız.</p>

                    <p>Bize ayrıca şu hakları
                        verirsiniz: (1) Semerkand Seyir Hizmeti'nin
                        işleyişini kolaylaştırmak amacıyla Semerkand Seyir Hizmeti'nin
                        Cihazınızdaki işlemciyi, bant genişliğini ve depolama donanımını kullanmasına
                        izin vermek, (2) size reklam ve diğer bilgileri sunmamıza ve Semerkand Seyir
                        Gizlilik Politikası uyarınca izin verildiği üzere iş ortaklarımızın da aynısını
                        yapmasına izin vermek.</p>

                    <p>&nbsp;</p>

                    <p>içerik Deneyimi</p>

                    <p>Semerkand Seyir Hizmeti'nin herhangi bir kısmında, seçimi ve yerleşimi
                        dahil olmak üzere eriştiğiniz içerik, Semerkand Seyir'ın
                        üçüncü taraflarla sözleşmeleri dahil olmak üzere ticari hususlardan
                        etkilenebilir.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir tarafından lisanslanan, sağlanan,
                        oluşturulan veya başka bir şekilde kullanıma sunulan bazı içerikler (ör. diziler)
                        reklam içerebilir ve ilgili reklamlardan Semerkand Seyir sorumlu değildir.</p>

                    <p>&nbsp;</p>

                    <p>ıhlal iddiaları</p>

                    <p>Semerkand Seyir, fikri mülkiyet hakkı
                        sahiplerinin haklarına saygı duyar. Herhangi bir içerik'in
                        telif haklarınızı ihlal ettiğini düşünüyorsanız lütfen Semerkand Seyir Telif
                        Hakkı Politikası'nı inceleyin.</p>

                    <p>&nbsp;</p>

                    <p>5. Müşteri Desteği, Bilgi, Sorular ve
                        şikayetler</p>

                    <p>&nbsp;</p>

                    <p>Müşteri Desteği, Bilgi, Sorular, şikayet</p>

                    <p>Hesapla ilgili ya da ödemeyle ilgili sorular
                        ("Müşteri Desteği Soruları") ile ilgili olarak lütfen web sitemizin Hakkımızda bölümünde yer alan Müşteri Hizmetleri
                        kaynaklarını kullanın.</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmeti ile veya bu şartlar
                        ile (buraya dahil edilen ek Semerkand Seyir şartları ve koşulları dahil) ilgili
                        herhangi bir sorunuz olması halinde lütfen web
                            sitemizin Hakkımızda bölümüne giderek Semerkand Seyir Müşteri Hizmetleri
                        ile iletişime geçin.</p>

                    <p>&nbsp;</p>

                    <p>Avrupa Birliği'nde ikamet
                        ediyorsanız alternatif anlaşmazlık çözümü için çevrimiçi platformdan da (ODR platformu) şikayette bulunabilirsiniz. ODR platformuna
                        şu bağlantıdan ulaşabilirsiniz: https://ec.europa.eu/consumers/odr.</p>

                    <p>&nbsp;</p>

                    <p>6. Sorunlar ve Anlaşmazlıklar</p>

                    <p>Semerkand Seyir Hizmetleri'ni askıya almak
                        veya feshetmek</p>

                    <p>Bu şartlar, sizin tarafınızdan ya da Semerkand
                        Seyir tarafından feshedilene kadar sizin için geçerli olmaya devam edecektir.
                        Bu şartlar'ı ihlal ettiğinizi düşünürsek, tarafınıza
                        makul bir süre öncesinde bildirimde bulunarak Semerkand Seyir Hizmeti'ni ya da herhangi bir önemli bileşenini tarafınıza
                        sağlamayı durdurursak veya geçerli kanunlara uyum amacıyla bunun gerekli
                        olduğunu düşündüğümüz durumlarda Semerkand Seyir, herhangi bir zamanda bu şartlar'ı (buraya dahil edilen ek Semerkand Seyir şartları
                        ve koşulları dahil) feshedebilir ya da Semerkand Seyir Hizmeti'ne
                        erişiminizi askıya alabilir. Siz veya Semerkand Seyir bu şartlar'ı
                        ihlal eder veya Semerkand Seyir sizin Semerkand Seyir Hizmeti'ne
                        erişiminizi askıya alırsa Semerkand Seyir'ın geçerli
                        kanunlara tabi olmak üzere tarafınıza karşı hiçbir yükümlülüğü ve sorumluluğu
                        olmayacağını (bu şartlarda aksi belirtilmediği takdirde) ve halihazırda yapmış
                        olduğunuz ödemeleri iade etmeyeceğini kabul edersiniz. Bu şartlar'ı
                        istediğiniz zaman feshedebilirsiniz. Bu durumda, Semerkand Seyir Hizmeti'ne erişim sağlamaya veya Semerkand Seyir 
                        Hizmeti'ni kullanmaya devam edemezsiniz. Semerkand Seyir
                        hesabınızı nasıl feshedeceğinizi öğrenmek için lütfen Hakkımızda
                            sayfamızdaki Müşteri Desteği kaynaklarından yararlanın.</p>

                    <p>&nbsp;</p>

                    <p>Buradaki 4. (içerik ve Fikri Mülkiyet
                        Hakları), 3. (Semerkand Seyir Hizmetini Kullanımınız), 2. (Tarafımızca Sunulan
                        Semerkand Seyir Hizmeti), 6. (Sorunlar ve Anlaşmazlıklar) ve 7. (Bu şartlar
                        Hakkında) maddeler ile açıkça ya da özellikleri gereği şartlar'ın
                        feshedilmesinden sonra yürürlükte kalması gereken diğer tüm maddeler, şartlar
                        feshedildikten sonra da yürürlükte kalacaktır.</p>

                    <p>&nbsp;</p>

                    <p>Garanti Reddi</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir, Semerkand Seyir Hizmetleri'ni
                        makul özen ve beceriyle ve Semerkand Seyir tarafından sağlanan Semerkand Seyir Hizmeti'nin tüm özelliklerine uygun olarak sağlamaya devam
                        edecektir. Ancak bu duruma tabi olmak üzere Semerkand Seyir Hizmeti
                        "olduğu gibi" ve "kullanılabilir olduğu şekliyle", hiçbir
                        türde açık, zımni veya yasal garanti olmaksızın sunulmaktadır. Ayrıca,
                        Semerkand Seyir yeterli kalite ile ilgili her türlü açık, zımni ve yasal
                        garantiyi reddetmektedir. Semerkand Seyir Semerkand Seyir Hizmeti'nin
                        kötü amaçlı yazılımlardan ya da başka zararlı bileşenlerden ari olduğuna dair
                        hiçbir garanti vermemektedir. Ayrıca Semerkand Seyir, hiçbir üçüncü taraf
                        uygulaması (veya bunların içeriği), kullanıcı içeriği, cihaz ya da Semerkand
                        Seyir Hizmeti üzerinden ya da aracılığıyla veya köprü verilen herhangi bir web
                        sitesinde bir üçüncü tarafça reklamı yapılan, tanıtılan veya sunulan herhangi
                        bir ürün ya da hizmete dair hiçbir beyanda bulunmaz, bunları garanti etmez veya
                        bunlara ilgili sorumluluk kabul etmez ve Semerkand Seyir, bunlara ilişkin
                        üçüncü taraf sağlayıcıları ile sizin aranızdaki hiçbir işlem için sorumlu ya da
                        yükümlü değildir. Sizin tarafınızdan Semerkand Seyir'dan
                        alınan sözlü ya da yazılı hiçbir tavsiye ya da bilgi, Semerkand Seyir adına
                        hiçbir garanti teşkil etmeyecektir. Bu madde geçerli kanunların izin verdiği
                        azami ölçüde uygulanacaktır.</p>

                    <p>&nbsp;</p>

                    <p>Bazı yargı bölgelerinde
                        tüketicinin geçerli yasal haklarına ilişkin zımni garantilerin veya
                        sınırlamaların hariç tutulmasına izin verilmemektedir. Bu tür bir durumda hariç
                        bırakma durumu ve sınırlamalar sizin için geçerli olmayabilir ve hiçbir şey
                        yasal haklarınızı etkilemeyecektir.</p>

                    <p>&nbsp;</p>

                    <p>Sorumluluk Sınırlaması ve ıddianın Gönderim
                        Süresi</p>

                    <p>&nbsp;</p>

                    <p>Semerkand Seyir Hizmeti'nde yaşadığınız herhangi bir sorunda ya da
                        memnuniyetsizlikte tek ve münhasır çözüm yolunuzun tüm Semerkand Seyir yazılımını
                        kaldırmak ve Semerkand Seyir Hizmeti'ni kullanmayı
                        durdurmak olduğunu kabul edersiniz. Semerkand Seyir'in Semerkand Seyir Hizmetleri aracılığıyla ya da bu
                            hizmetlerle bağlantılı olarak kullanıma sunulan üçüncü taraf uygulamaları veya
                            içeriklerden kaynaklanan ya da bunlarla bağlantılı hiçbir yükümlülüğü ve
                            sorumluluğu bulunmadığını; ilgili üçüncü taraflarla aranızdaki ilişki söz
                            konusu üçüncü taraflarla aranızdaki ayrı sözleşmelere tabi olsa da Semerkand Seyir'e ilişkin olarak buradaki üçüncü taraf uygulamalarında ya da içerikte yaşadığınız her türlü sorun
                                veya memnuniyetsizlik için tek ve münhasır çözüm yolunuzun söz konusu üçüncü
                                taraf uygulamalarını kaldırmak ve/veya kullanmayı bırakmak olduğunu kabul
                                edersiniz.</p>

                    <p>&nbsp;</p>

                    <p>**Semerkand Seyir, yetkilileri, hissedarları,
                        çalışanları, temsilcileri, yöneticileri, bağlı ortaklıkları, iştirakleri,
                        halefleri, görevlileri, tedarikçileri veya lisans sağlayıcıları hiçbir durumda
                        şunlardan sorumlu olmayacaktır: (1) her türlü dolaylı, özel, arızi, cezai,
                        örnek niteliğindeki veya bağlı zarardan; (2) Semerkand Seyir Hizmeti'nin, cihazların, üçüncü taraf uygulamalarının veya
                        üçüncü taraf uygulama içeriğinin kullanılmasından veya kullanılamamasından
                        kaynaklanan her durumda kullanım kaybı, veri kaybı, iş veya kâr kaybından (doğrudan
                        veya dolaylı); veya (3) Semerkand Seyir Hizmeti'ne,
                        üçüncü taraf uygulamalarına veya üçüncü taraf uygulama içeriğine ilişkin tüm
                        iddialar için (a) Semerkand Seyir'e ilk iddiadan önceki on iki ay içinde ödemiş olduğunuz tutar
                            veya (b) 30,00 $ arasında hangisi daha yüksekse ilgili tutardaki toplam
                            yükümlülük. Maruz kaldığınız zararlara yönelik yükümlülüğümüz, makul
                        olarak öngörülebilir zararlarla sınırlıdır. **</p>

                    <p>&nbsp;</p>

                    <p>şüpheye mahal vermemek adına bu şartlar,
                        geçerli kanunun ilgili sınırlamayı yasakladığı ölçüde Semerkand Seyir'ın dolandırıcılık, dolandırma amaçlı yanlış beyan,
                        ölüm veya bedensel yaralanmaya ilişkin yükümlülüğünü ya da geçerli kanunlar
                        çerçevesinde sınırlanamaz veya hariç bırakılamaz herhangi bir başka yükümlülüğü
                        sınırlamaz.</p>

                    <p>&nbsp;</p>

                    <p>**Bu tür kısıtlamaların
                        geçerli kanunlar tarafından yasaklandığı durumlar hariç olmak üzere, bu şartlar
                        kapsamındaki tüm iddialar, iddiada bulunan tarafın iddiaya yol açan eylemi,
                        ihmali ya da kusuru ilk öğrendiği ya da makul olarak öğrenmiş olması gereken
                        tarihten itibaren bir (1) yıl içinde (tahkim talebinde bulunarak veya aşağıdaki
                        tahkim anlaşması kapsamında bireysel bir dava açarak) başlatılmalıdır ve bu
                        süre içerisinde belirtilmeyen hiçbir iddia için hiçbir çözüm yolu hakkı
                        bulunmayacaktır. **</p>

                    <p>&nbsp;</p>

                    <p>Üçüncü Taraf Hakları</p>

                    <p>içerik sahiplerinin ve belirli
                        distribütörlerin (uygulama mağazası sağlayıcıları gibi) şartlar'ın
                        amaçlanan lehtarları olduğunu ve şartlar'ın doğrudan
                        aleyhinize uygulama hakkına sahip olduğunu kabul eder ve onaylarsınız. Bu
                        maddede belirtilen durumlar haricinde, şartlar ile siz ve Semerkand Seyir
                        haricinde herhangi birine haklar verilmesi amaçlanmamış olup şartlar hiçbir
                        durumda hiçbir üçüncü taraf lehtar hakları oluşturmayacaktır.</p>

                    <p>&nbsp;</p>

                    <p>Mobil yazılım uygulamalarımızdan herhangi
                        birini (her biri bir "Uygulama") Apple Inc.
                        ("Apple") App Store'dan
                        indirdiyseniz ya da Uygulama'yı bir iOS cihazında kullanıyorsanız Apple'a
                        ilişkin aşağıdaki bildirimi okuduğunuzu, anladığınızı ve kabul ettiğinizi
                        onaylarsınız. Bu şartlar Apple ile değil, yalnızca sizinle Semerkand Seyir
                        arasındadır ve Apple, Semerkand Seyir Hizmeti'nden ya
                        da içeriğinden sorumlu değildir. Apple, Semerkand Seyir Hizmeti ile ilgili
                        olarak hiçbir bakım veya destek hizmeti sunmakla yükümlü değildir. Semerkand Seyir Hizmeti'nin
                            geçerli herhangi bir garantiye uygun olmaması halinde Apple'a
                            bildirimde bulunabilirsiniz ve Apple, Uygulama'nın
                            geçerli satın alma fiyatını size iade eder ve geçerli kanunların izin
                        verdiği azami ölçüde Apple'ın, Semerkand Seyir Hizmeti'ne ilişkin olarak başka hiçbir garanti yükümlülüğü
                        bulunmamaktadır. Apple, şunlar dahil olmak üzere, sizin tarafınızdan ya da
                        herhangi bir üçüncü tarafça, Semerkand Seyir Hizmeti'ne
                        ya da Semerkand Seyir Hizmeti'ni elinizde
                        bulundurmanıza veya kullanmanıza ilişkin olarak iletilen hiçbir iddiayı ele almaktan
                        sorumlu değildir: (1) ürün sorumluluğu iddiaları; (2) Semerkand Seyir Hizmeti'nin geçerli herhangi bir yasal gerekliliğe ya da
                        düzenleme gerekliliğine uymadığına dair herhangi bir iddia; (3) tüketicinin
                        korunması kanunu ya da benzer kanunlar kapsamında ortaya çıkan iddialar ve (4)
                        fikri mülkiyet ihlaline yönelik iddialar. Apple, Semerkand Seyir Hizmeti'nin veya Uygulama'yı
                        bulundurmanızın ve kullanmanızın söz konusu üçüncü tarafın fikri mülkiyet
                        haklarını ihlal ettiğine dair hiçbir üçüncü taraf iddiasını araştırmaktan,
                        savunmaktan, çözümlemekten ya da gidermekten sorumlu değildir. Semerkand Seyir Hizmeti'ni kullanırken geçerli tüm üçüncü taraf şartlarına
                        uymayı kabul edersiniz. Apple ve Apple'ın alt
                        kuruluşları bu şartlar'ın üçüncü taraf lehtarlarıdır ve
                        bu şartlar'ı kabul ettiğinizde Apple, bu şartlar'ın bir üçüncü tarafı olarak bu şartlar'ı
                        sizin aleyhinize uygulama hakkına sahip olur (ve bu hakkı kabul ettiği
                        varsayılır).</p>

                    <p>&nbsp;</p>

                    <p>Tazmin</p>

                    <p>şunlardan kaynaklanan veya bunlarla bağlantılı
                        olan ve makul olarak öngörülebilir her türlü zarar, kayıp ve makul masrafa
                        (makul avukatlık ücretleri ve maliyetleri de dahil) karşı Semerkand Seyir'ı tazmin etmeyi ve beri kılmayı kabul edersiniz: (1) şartlar'ı ya da şartlar'dan
                        herhangi birini (buraya dahil edilen ek Semerkand Seyir şartları ve koşulları
                        dahil) ihlal etmeniz; (2) yayınladığınız veya başka bir şekilde katkıda
                        bulunduğunuz herhangi bir Kullanıcı ıçeriği; (3) Semerkand Seyir Hizmeti
                        üzerinde ya da aracılığıyla dahil olduğunuz herhangi bir faaliyet ve (4)
                        herhangi bir kanunu ya da bir üçüncü tarafın haklarını ihlal etmeniz.</p>

                    <p>&nbsp;</p>

                    <p>Kanun Seçimi, Zorunlu Tahkim ve Yargı Yeri</p>

                    <p>6.1 Geçerli Kanun / Yargı Yetkisi</p>

                    <p>ıkamet ettiğiniz ülkenin zorunlu kanunları
                        kapsamında aksi gerekmediği müddetçe, Sözleşmeler (ve bunlar nedeniyle ya da
                        bunlarla bağlantılı olarak ortaya çıkan tüm sözleşme dışı
                        anlaşmazlıklar/iddialar), kanun seçimi ya da kanun çatışması ilkeleri dikkate alınmaksızın
                        aşağıda listelenen eyaletin ya
                        da ülkenin kanunlarına tabidir.</p>

                    <p>&nbsp;</p>

                    <p>Ayrıca, Sözleşmeler ile (ve bunlar nedeniyle
                        ya da bunlarla bağlantılı olarak ortaya çıkan tüm sözleşme dışı
                        anlaşmazlıklarla/iddialarla) bağlantılı olarak oluşan her türlü anlaşmazlık,
                        iddia ve çatışmayı çözmek için siz ve Semerkand Seyir, aşağıda listelenen
                        mahkemelerin yargı yetkisine tabi olmayı kabul edersiniz. Ancak geçerli zorunlu
                        kanunlar çerçevesinde ikamet ettiğiniz ülkede yasal işlem başlatmayı
                        seçebilirsiniz veya sadece ikamet ettiğiniz ülkede yasal işlem başlatabiliyor
                        olabiliriz.</p>

                    <p>&nbsp;</p>

                    <p>6.1 Geçerli Kanun / Yargı Yetkisi</p>

                    <p>ıkamet
                            ettiğiniz ülkenin zorunlu kanunları kapsamında aksi gerekmediği müddetçe,
                            Sözleşmeler (ve bunlar nedeniyle ya da bunlarla bağlantılı olarak ortaya çıkan
                            tüm sözleşme dışı anlaşmazlıklar/iddialar), kanun seçimi ya da kanun çatışması
                            ilkeleri dikkate alınmaksızın aşağıda listelenen eyaletin ya da ülkenin
                            kanunlarına tabidir.</p>

                    <p>Ayrıca,
                            Sözleşmeler ile (ve bunlar nedeniyle ya da bunlarla bağlantılı olarak ortaya
                            çıkan tüm sözleşme dışı anlaşmazlıklarla/iddialarla) bağlantılı olarak oluşan
                            her türlü anlaşmazlık, iddia ve çatışmayı çözmek için siz ve Semerkand Seyir,
                            aşağıda listelenen mahkemelerin yargı yetkisine tabi olmayı kabul edersiniz.
                            Ancak geçerli zorunlu kanunlar çerçevesinde ikamet ettiğiniz ülkede yasal işlem
                            başlatmayı seçebilirsiniz veya sadece ikamet ettiğiniz ülkede yasal işlem
                            başlatabiliyor olabiliriz</p>

                    <table Table border="0" cellpadding="0">
                    <thead>
                        <tr>
                            <td>
                                <p align="center" ><b>Ülke veya bölge</b></p>
                            </td>
                            <td>
                                <p align="center"><b>Kanun Seçimi</b></p>
                            </td>
                            <td>
                                <p align="center"><b>Yargı bölgesi</b></p>
                            </td>
                        </tr>
                    </thead>
                    <tr>
                        <td valign="top">
                            <p>Semerkand Seyir'in kullanılabildiği diğer tüm ülkeler ve bölgeler.</p>
                        </td>
                        <td valign="top">
                            <p>isveç</p>
                        </td>
                        <td valign="top">
                            <p>Münhasır; isveç Mahkemeleri</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Bulgaristan, Kıbrıs,
                                    Estonya, Fransa, Hong Kong, Letonya, Litvanya, Lüksemburg, Malta, Monako,
                                    Norveç, Filipinler, Portekiz, Slovakya, ıspanya, Türkiye</p>
                        </td>
                        <td valign="top">
                            <p>isveç Kanunları</p>
                        </td>
                        <td valign="top">
                            <p>Münhasır değil; isveç Mahkemeleri</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Brezilya</p>
                        </td>
                        <td valign="top">
                            <p>Brezilya Kanunları</p>
                        </td>
                        <td valign="top">
                            <p>Münhasır; São Paulo Eyalet ve Federal Mahkemeleri, Brezilya'da São Paulo Eyaleti</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Kanada</p>
                        </td>
                        <td valign="top">
                            <p>Quebec'te ikamet edenler için
                                    geçerli olmayan yasalar: Ontario Eyaleti YasalarıQuebec'te ikamet edenler için geçerli yasalar:
                                    Kanada'da Quebec Eyaletinin Yasaları</p>
                        </td>
                        <td valign="top">
                            <p>Quebec'te ikamet edenler için
                                    geçerli olmayan yasalar: Hüküm verme amacı haricinde münhasır; Kanada'da Ontario MahkemeleriQuebec'te
                                    ikamet edenler için geçerli yasalar: Kanada'da Quebec
                                    Mahkemeleri</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Arjantin, Bolivya,
                                    şili, Kolombiya, Kosta Rika, Dominik Cumhuriyeti, Ekvador,
                                    El Salvador, Guatemala, Honduras, Nikaragua, Panama, Paraguay, Peru, Uruguay</p>
                        </td>
                        <td valign="top">
                            <p>Amerika Birleşik
                                    Devletleri'nde Kaliforniya Eyaleti</p>
                        </td>
                        <td valign="top">
                            <p>Münhasır; New
                                    York'ta New York veya Kaliforniya'da San Francisco ıdari Bölgesi Eyalet ve
                                    Federal Mahkemeleri</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Birleşik Krallık</p>
                        </td>
                        <td valign="top">
                            <p>ıngiltere ve Galler
                                    Yasaları</p>
                        </td>
                        <td valign="top">
                            <p>Münhasır</p>
                        </td>
                    </tr>
                </table>

                <p>&nbsp;</p>

                <p>Münhasır</p>

                <p>&nbsp;</p>

                <p>6.2 TOPLU DAVA FERAGATI</p>

                <p>GEÇERLi KANUNLARIN İZİN VERDiği DURUMLARDA SİZ
                    VE SEMERKAND SEYİR, BİRBİRİNİZE KARŞI HERHANGi BiR TOPLU YA DA TEMSİLİ GİBİ
                    GÖRÜNEN DAVADA BİR DAVACI YA DA TOPLU DAVA TARAFI OLARAK DEĞİL, YALNIZCA KENDı
                    SIFATINIZLA iDDiADA BULUNABİLECEĞİNİZİ KABUL EDERSİNİZ. Siz ve Semerkand Seyir
                    birlikte kabul etmediğiniz müddetçe, hiçbir hakem ya da hakim
                    birden fazla kişinin iddialarını birleştiremez veya başka bir şekilde herhangi
                    bir türde bir temsili dava ya da toplu dava yürütemez.</p>

                <p>&nbsp;</p>

                <p>6.3 TAHKiM</p>

                <p>Bu 6.3. maddenin yürütülebilir olduğu bir
                    yargı bölgesinde bulunmanız, merkezinizin burada olması, burada ofisleriniz
                    olması ya da iş yapmanız halinde aşağıdaki zorunlu tahkim hükümleri sizin için
                    geçerlidir:</p>

                <p>&nbsp;</p>

                <p>6.3.1 Anlaşmazlıkların çözümü ve tahkim</p>

                <p>Siz ve Semerkand Seyir, bu Sözleşmeler ile
                    veya Hizmet'in bir kullanıcısı olarak Semerkand Seyir
                    ile aranızdaki ilişkiyle herhangi bir şekilde bağlantılı ya da ilişkili olarak
                    Semerkand Seyir ile aranızda oluşan her türlü anlaşmazlığın, iddianın veya
                    çatışmanın (sözleşmeye, haksız fiile, yasaya, dolandırıcılığa, yanlış beyana ya
                    da başka bir yasal teoriye dayalı olarak veya iddianın Sözleşmeler süresince ya
                    da Sözleşmeler sona erdikten sonra oluşmasından bağımsız olarak), zorunlu
                    bağlayıcı bireysel tahkim ile çözümleneceğini kabul edesiniz. Tahkim, mahkemede
                    görülen bir davaya göre daha gayriresmidir. TAHKiMDE HAKiM YA DA JÜRi BULUNMAZ VE TAHKiM KARARININ MAHKEMECE
                    iNCELENMESi SINIRLIDIR. Mahkemeye göre daha sınırlı tespitler yapılabilir.
                    Hakemin bu karara uyması gerekir ve bir mahkemeyle aynı tazminat ve zararın
                    telafisi kararını verebilir (avukatlık ücretleri dahil) ancak hakem, tahkimin
                    taraflarından başka herhangi bir taraf lehinde açıklayıcı ya da ihtiyati tedbir
                    kararı veremez. Bu tahkim hükmü, Sözleşmeler sona erdikten sonra da yürürlükte
                    kalacaktır.</p>

                <p>&nbsp;</p>

                <p>6.3.2 ıstisnalar</p>

                <p>Yukarıdaki 6.3.1. maddeden bağımsız olarak siz
                    ve Semerkand Seyir, burada yer alan hiçbir şeyin sizin ya da bizim şunlara
                    ilişkin haklarımızdan feragat ettiğimiz, men edildiğimiz veya bu haklarımızın
                    başka bir şekilde sınırlandırıldığı anlamına gelmeyeceğini kabul ederiz: (1) küçük
                    anlaşmazlıklara bakan mahkemelerde bireysel dava açma (2) haciz işlemleri
                    olduğu durumlarda, bu haciz işlemlerini geçerli eyalet kurumları, federal ve
                    yerel kurumlar aracılığıyla yaptırma, (3) bir hukuk mahkemesinde ihtiyati
                    tedbir kararı aldırma veya (4) fikri mülkiyet ihlali iddialarını ele almak için
                    bir hukuk mahkemesinde dava açmak.</p>

                <p>&nbsp;</p>

                <p>6.3.3 Tahkim kuralları</p>

                <p>Tahkim işlemleri sizin tarafınızdan ya da
                    bizim tarafımızdan başlatılabilir. Sizinle Semerkand Seyir arasındaki tüm
                    tahkimler, ilgili tarihte yürürlükte olan Uluslararası Ticaret Odası
                    ("ICC") Tahkim Kurallarına ("ICC Kuralları") göre, bu
                    Sözleşmeler tarafından tadil edildiği şekliyle ICC Kuralları'na
                    uygun şekilde atanan bir veya daha fazla hakim
                    tarafından nihai olarak sonuçlandırılacak ve ICC Uluslararası Tahkim Mahkemesi
                    tarafından yönetilecektir.</p>

                <p>&nbsp;</p>

                <p>Tüm tahkimler ıngilizce olarak yürütülecek ve
                    Avrupa Birliği üyesi bir ülkenin ya da başka bir yargı bölgesinin zorunlu bir
                    kanunu aksini gerektirmediği müddetçe tüm tahkimlerde uygulanacak olan kanun,
                    [6.1. maddede belirtilen ilgili eyalet veya ülke kanunu] olacak ve kanun seçimi
                    ya da kanun çatışması ilkeleri dikkate alınmayacaktır.</p>

                <p>&nbsp;</p>

                <p>6.3.4 Gönderim süresi</p>

                <p>Tüm tahkimler, iddiada bulunan tarafın iddiaya
                    yol açan eylemi, ihmali ya da kusuru ilk öğrendiği ya da makul olarak öğrenmiş
                    olması gereken tarihten itibaren BıR (1) YIL içinde bir talep gönderilerek
                    başlatılmalıdır ve bu süre içerisinde belirtilmeyen hiçbir iddia için hiçbir
                    çözüm yolu hakkı bulunmayacaktır. Geçerli kanunlar, iddiada bulunmaya yönelik
                    bir yıllık süre sınırlamasına izin vermiyorsa iddialar, geçerli kanun
                    kapsamında izin verilen en kısa sürede belirtilmelidir.</p>

                <p>&nbsp;</p>

                <p>6.3.5 Bildirim; Tebligat</p>

                <p>Tahkime başvurmak isteyen bir taraf, öncelikle
                    taahhütlü postayla ya da Federal Express (imza gereklidir) hizmetiyle veya
                    sizin kayıtlarınızda fiziksel adresimiz mevcut değilse elektronik posta yoluyla
                    karşı tarafa yazılı bir bildirim ("Bildirim") göndermelidir.
                    Semerkand Seyir'in Bildirim adresi: [Semerkand Seyir, Eyüp Sultan Mah. Esma Sk.
                        No:7/A 34885 Samandıra Sancaktepe - İSTANBUL /
                        TÜRKıYE]. Bildirimde (1) iddianın ya da anlaşmazlığın
                            özelliği ile temeli açıklanmalı ve (2) istenen özel tazmin kararı
                            ("Talep") belirtilmelidir. ıddiayı doğrudan çözümlemek için iyi
                            niyetle hareket etmeyi kabul ederiz ancak Bildirim alındıktan sonra 30 gün
                            içinde bu şekilde bir anlaşmaya varamazsak sizin tarafınızdan ya da Semerkand
                            Seyir tarafından bir tahkim işlemi başlatılabilir. Tahkim sırasında, sizin
                            tarafınızdan ya da Semerkand Seyir tarafından önerilen herhangi bir uzlaşma
                            tutarı (varsa), hakem nihai bir karar ve hüküm verene kadar hakeme
                            açıklanmamalıdır. Anlaşmazlığımızın tahkim aracılığıyla nihai olarak lehinize
                            çözümlenmesi halinde Semerkand Seyir (1) varsa hakem tarafından kararlaştırılan
                            tutarı, (2) hakemin kararından önce anlaşmazlığın çözümü için Semerkand Seyir
                            tarafından teklif edilen en son yazılı uzlaşma tutarı veya (3) 1.000,00 $'nı (hangisi
                        en fazlaysa) size ödeyecektir. Tahkim sırasında açıklanan tüm belgeler ve
                        bilgiler alıcı tarafından kesinlikle gizli tutulacak ve alıcı tarafından
                        tahkimin amaçları ya da hakemin kararının ve hükmümün uygulanması dışında
                        hiçbir amaç için kullanılmayacak ve bu tür amaçlar için ya da geçerli
                        kanunların gerektirdiği şekilde bilmesi gereken kişilere gizlilikle açıklama
                        haricinde açıklanmayacaktır.Hakemin kararını ve
                        hükmünü uygulamak için gereken durumlar haricinde siz ya da Semerkand Seyir,
                        tarafların anlaşmazlık içinde olduğu, tahkimin yapıldığı veya hakem tarafından
                        herhangi bir karar ya da hüküm verildiği bilgisi dahil ancak bunlarla sınırlı
                        olmamak üzere hiçbir kamu duyurusu ya da kamuya açık yorum yapmayacaksınız veya
                        tahkimle ilgili hiçbir propaganda başlatmayacaksınız.</p>

                <p>&nbsp;</p>

                <p>6.3.6 Değişiklikler</p>

                <p>Semerkand Seyir bu tahkim hükmünde gelecekte
                    herhangi bir değişiklik (Semerkand Seyir'ın Bildirim
                    adresi değişikliği hariç) yaparsa 30 gün içinde Semerkand Seyir'ın
                    Bildirim adresine yazılı bir bildirim göndererek söz konusu değişikliğe itiraz
                    edebilirsiniz. Bu durumda, Semerkand Seyir'daki
                    hesabınız derhal sonlandırılır ve bu tahkim hükmü, reddettiğiniz tadillerden
                    hemen önce yürürlükte olduğu şekliyle geçerli kalmaya devam eder.</p>

                <p>&nbsp;</p>

                <p>6.3.7 Yürütülebilirlik</p>

                <p>6.2. Madde'deki
                    toplu dava feragatinin tahkimde yürütülemez olduğu ya da bu 6.3. Madde'nin
                    herhangi bir kısmının geçersiz ya da yürütülemez olduğu anlaşılırsa bu 6.3.
                    Madde'nin tamamı geçersiz ve hükümsüz olacaktır ve bu durumda taraflar, Sözleşmeler'den kaynaklanan ya da bunlarla bağlantılı
                    olarak oluşacak tüm davaların 6.1. Madde'de açıklanan
                    münhasır yargı yetkisi ve yargı yerine tabi olacağını ve herhangi bir zamanda
                    dava açmanızın engellenmeyeceğini kabul ederler.</p>

                <p>&nbsp;</p>

                <p>7. Bu şartlar Hakkında</p>

                <p>Geçerli kanuna göre bir sözleşmeyle
                    sınırlanamayacak belirli haklara sahip olabilirsiniz. Bu şartlar hiçbir şekilde
                    bu hakları sınırlamayı amaçlamaz.</p>

                <p>&nbsp;</p>

                <p>Değişiklikler</p>

                <p>Bu şartlar'da
                    (buraya referans olarak dahil edilen diğer Semerkand Seyir şartları ve
                    koşulları dahil) zaman zaman sizi ilgili değişiklikler konusunda (değişiklikler
                    geçerli olmadan önce), geçerli Semerkand Seyir Hizmeti üzerinden revize edilmiş bir Sözleşme yayınlamak dahil olmak üzere
                    makul yöntemlerle (önemli değişikliklerde bu bildirimi e-posta, hizmet içi
                    açılır mesaj veya başka yöntemlerle tamamlamaya çalışmamız şartıyla)
                    bilgilendirmek suretiyle değişiklikler yapabiliriz. ılgili değişiklikler, revize edilen şartlar'ı ya da
                    diğer Semerkand Seyir şartları ve koşullarını yayınladığımız, bu
                    değişikliklerin dahil edildiği veya sizi ilgili değişiklikler hakkında
                    bilgilendirdiğimiz tarihten önce sizinle aramızdaki herhangi bir anlaşmazlık
                    için geçerli olmayacaktır. Bu şartlar'da yapılacak
                    herhangi bir değişiklik sonrasında Semerkand Seyir Hizmeti'ni
                    kullanmanız, bu değişiklikleri kabul ettiğiniz anlamına gelir. Güncellenen
                    şartlar kapsamında Semerkand Seyir Hizmeti'ni
                    kullanmaya devam etmek istemiyorsanız bizimle iletişime geçerek hesabınızı
                    sonlandırabilirsiniz. Bu belgenin en başında belirtilen yürürlük tarihi, bu şartlar'ın en son değiştirildiği tarihi gösterir.</p>

                <p>&nbsp;</p>

                <p>Sözleşmenin Bütünlüğü</p>

                <p>Bu maddede belirtilen ya da Semerkand Seyir
                    ile aranızda yazılı olarak açıkça anlaşılan durumlar haricinde, şartlar
                    Semerkand Seyir ile sizin aranızda kabul edilen tüm şartları ve koşulları
                    teşkil eder ve bu şartlar'ın konusuna ilişkin olarak
                    önceden var olan yazılı veya sözlü tüm sözleşmelerin yerini alır. Yukarıda
                    belirtildiği üzere, aşağıdaki şartlar ve koşullar dahil olmak üzere Semerkand
                    Seyir Hizmeti'nin kullanımının tabi olduğu diğer
                    şartlar ve koşullar buraya referans olarak dahil edilmiştir: Semerkand Seyir Premium Promosyon Teklifi şartları, Semerkand
                        Seyir Kart şartları, Semerkand Seyir Kullanıcı ılkeleri, Semerkand Seyir Telif
                        Hakkı Politikası ve Semerkand Seyir Destek Topluluğu şartları.</p>

                <p>&nbsp;</p>

                <p>Bölünebilirlik ve Feragat</p>

                <p>şartlar'da aksi belirtilmiş olan durumlar haricinde, şartlar'ın
                    herhangi bir hükmünün herhangi bir nedenle veya herhangi bir ölçüde geçersiz ya
                    da yürütülemez kılınması durumunda şartlar'ın geri
                    kalan hükümleri etkilenmeyecek ve söz konusu hüküm kanunların izin verdiği
                    azami ölçüde uygulanacaktır.</p>

                <p>&nbsp;</p>

                <p>Semerkand Seyir'ın
                    ya da herhangi bir üçüncü taraf lehtarın şartlar'ı ya
                    da herhangi bir hükmünü uygulamaması, Semerkand Seyir'ın
                    veya geçerli üçüncü taraf lehtarın bu hakkında feragat ettiği anlamına
                    gelmeyecektir.</p>

                <p>&nbsp;</p>

                <p>Devir ve Temlik</p>

                <p>Semerkand Seyir, şartlar'ı
                    ve şartlar'daki haklarından herhangi birini tamamen
                    ya da kısmen devir ve temlik edebilir ve Semerkand Seyir, şartlar kapsamındaki
                    yükümlülüklerinden herhangi birini devredebilir. Siz şartlar'ı
                    hiçbir bir üçüncü tarafa tamamen ya da kısmen devir ve temlik edemez veya
                    şartlar kapsamındaki haklarınızı devredemez veya alt lisansını veremezsiniz.</p>

                </div>
                <Footer/>
            </main >
        )
    }
}
export default TermsOfUse;

