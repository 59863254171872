import React, { Component } from 'react'
import SquareImageViewer from "./SquareImageViewer";
import Loader from "./Loader";
import api from '../services/api';
    
    export default class DetailHighlight extends Component {
        componentDidMount(){            
            var highlights = localStorage.getItem('highlights');
            var jsonData = JSON.parse(highlights);
            
            if(highlights === undefined || highlights === null || highlights === "" || jsonData.expireat < new Date().getTime()){
                api.get(window.apibaseurl + '/content/trending_now?limit=12', {}, true)
                .then(resp=> {
                    var data = {}
                    var date = new Date();
                    data.expireat = date.getTime() + 150*60000
                    data.videos = resp.data;
                    this.setState({
                        isPageLoader:false,
                        videos: resp.data
                    })
                    localStorage.setItem('highlights', JSON.stringify(data))
                })
            }
            else{
                this.setState({
                    isPageLoader:false,
                    videos: jsonData.videos
                })
            }
            
        }
        state = {
            isPageLoader: true,
            videos:[]            
        }
        render() {
            return (
                this.state.isPageLoader
                ? <section className="container-box mt-100">
                    <h3 className="font-22 color-white font-w500">Önce Çıkanlar</h3>
                    <div className="seperator seperator-darkgray mtb-20"></div>
                    <Loader />
                    </section>
                :  <section className="container-box mt-100">
                <h3 className="font-22 color-white font-w500">Önce Çıkanlar</h3>
                <div className="seperator seperator-darkgray mtb-20"></div>
                <div className="row row-30 column-count-3 row-s-0 mtb-30">
                    {
                    this.state.videos.map(video => {
                        var parent_id = video.parent_id;
                        if(parent_id === undefined || parent_id === null){ 
                            parent_id = video.id;
                        }
                        const rand = 10000 + Math.random() * (99999 - 10000);
                        if(video.video.length > 0){
                            return( 
                            <div className="col33 mb-60" key={video.id}>
                                <SquareImageViewer 
                                    key={video.id}
                                    id={video.id}
                                    rid={rand}
                                    parentId = {parent_id}
                                    title={video.title ?? ""}
                                    thumb={video.video[0].thumbnails["horizontal"]}
                                    videoUrl={video.video[0].url}
                                    preview={false}
                                />
                            </div>
                            )    
                        }
                        else{
                            return( 
                                <div className="col33 mb-60" key={video.id}>
                                    <SquareImageViewer 
                                        key={video.id}
                                        id={video.id}
                                        rid={rand}
                                        parentId = {parent_id}
                                        title={video.title}
                                        thumb={"https://via.placeholder.com/733x446.png?text=" + video.title}
                                        videoUrl={""}
                                        preview={false}
                                    />
                                </div>
                            )    
                        }
                        
                    })
                }
                    
                </div>
                </section>

            )
            
        }
    }
