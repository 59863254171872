const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  };
  const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.access_token;
  };
  const updateLocalAccessToken = (token_type, token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.token = token;
    user.token_type = token_type;
    user.access_token = token_type + " " + token;
    localStorage.setItem("user", JSON.stringify(user));
  };
  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  const setUser = (data) => {
    //data.access_token = data.token_type + " " + data.token;
    data.access_token = "Bearer " + data.token;

    var name = data.user.name;
    var namesurname = data.user.name_surname;
    if(name == null) name = "";
    if(namesurname == null) namesurname = "";
    data.user.name = name;
    data.user.surname = namesurname.replace(name + " ", "");
    data.user.name_surname = namesurname;

    localStorage.setItem("user", JSON.stringify(data));
  };
  const removeUser = () => {
    localStorage.removeItem("user");
  };
  const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
  };
  export default TokenService;