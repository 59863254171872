import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../services/api';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

let emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
const MySwal = withReactContent(Swal);

class ForgotPassword extends Component {
    
    render() {
        return (
            <main className="clear-after overflow-hidden">
                <section className="register-page ptb-200">
                    <ForgotPasswordForm />
                </section>
            </main>
        )
    }
}
export default ForgotPassword;


export function ForgotPasswordForm() {
    const [sendingForm, sendingToggle] = useState(false);
    const {register, handleSubmit, errors } = useForm();
    
    const onSubmit = data => {
        sendingToggle(true);
        api.post(window.apibaseurl + '/auth/forgot_password', data, false)
        .then(resp=> {
            //data.email = resp.data.user.name_surname;
            console.log(data);
            MySwal.fire({
                html: <p>{resp.data.message}</p>,
                icon: 'success',
                showConfirmButton: false,
                allowOutsideClick: false            
            })
        })
        .catch(function (error) {
            sendingToggle(false);
            if (error.response) {
                if(error.response.status === 400){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
                else if(error.response.status === 404){
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
                else{
                    MySwal.fire({
                        html: <p>{error.response.data.message}</p>,
                        icon: 'info',
                        confirmButtonText: 'Kapat'
                       
                    })
                }
            }
        });
    }
     
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="register-form ptb-50 plr-30">
            <h1 className="font-30 font-w600 color-white text-center">Şifremi Unuttum</h1>
            <input id="email" name="email" type="text" ref={register({ required: true,  pattern: emailPattern })} className={"form-input form-input-dark mtb-5" + (errors.email ? " error" : "")} placeholder="Mail adresiniz" />
           
            <button type="submit" className={"btn btn-white btn-rounded width-max mt-20 font-w500" + (sendingForm === true ? " sending": "")}>Gönder</button>

            <p className="font-16 color-gray font-w400 text-center mt-30">
                Giriş bilgilerine sahip misiniz? <Link to="/login" className="color-white">Giriş Yap</Link>
            </p>
        </form>
    );
  }